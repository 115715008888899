import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { LoadingService } from '../services/loading.service';
import { catchError, tap } from 'rxjs/operators';

@Injectable()
export class NetworkInterceptor implements HttpInterceptor {
    constructor(private loader: LoadingService) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        this.loader.show();
        return next.handle(request).pipe(
            tap(() => {
                this.loader.hide();
            }),
            catchError((err) => {
                this.loader.hide();
                if (err.status === 401 || err.status === 403) {
                    const newHttpErrorResponse = new HttpErrorResponse({
                        error: 'User or application not authorized for access.',
                        headers: err.headers,
                        status: err.status,
                        statusText: err.statusText,
                        url: err.url,
                    });

                    return throwError(() => newHttpErrorResponse);
                }
                return throwError(() => err);
            }),
        );
    }
}
