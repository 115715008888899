<div class="row">
    <div class="col-12">
        <mat-toolbar class="mat-elevation-z3">
            <div class="col-8">
                <ng-container *ngIf="getShowCreateDiversion() | async">
                    <fa-icon [icon]="faCodeBranch" [fixedWidth]="true" size="lg"></fa-icon>
                    Add Trip Diversion
                </ng-container>
            </div>
            <div class="col-4 right-align-toolbar">
                <button
                    *ngIf="getShowCreateDiversion() | async"
                    mat-stroked-button
                    (click)="saveDiversion()"
                    [disabled]="!isFormValid"
                >
                    <fa-icon [icon]="faFloppyDisk"></fa-icon>
                    Save Diversion
                </button>
                <button
                    *ngIf="getShowCreateDiversion() | async"
                    mat-stroked-button
                    (click)="onCancelClick()"
                >
                    Cancel
                </button>
                <button
                    *ngIf="tripIds.length === 1 && !(getShowCreateDiversion() | async)"
                    mat-stroked-button
                    (click)="onCreateClick()"
                >
                    <fa-icon [icon]="faPlus"></fa-icon>
                    Create Diversion
                </button>
            </div>
        </mat-toolbar>
    </div>
</div>

<div class="row" *ngIf="getShowCreateDiversion() | async">
    <div class="bds-error-message">{{ errorMessage }}</div>
    <rt-diversion-details
        #addDiversionComponent
        [diversion]="diversion"
        [splcStore]="splcList"
        [routeStore]="routeList"
        [businessGroups]="businessGroupList$ | async"
        [fleetNames]="fleetList$ | async"
        [whoPays]="whoPays$ | async"
        [diversionTypes]="divertTypes$ | async"
        [isSaving]="isSaving"
        (cancel)="onCancelClick()"
        (save)="onSaveClick($event)"
        (formValidity)="onFormValidityChange($event)"
        [tripIds]="tripIds"
    >
    </rt-diversion-details>
    <mat-divider></mat-divider>
</div>
