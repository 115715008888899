import {
    CdkDragDrop,
    CdkDragEnter,
    CdkDragExit,
    CdkDragSortEvent,
    moveItemInArray,
} from '@angular/cdk/drag-drop';
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { faFastrac, faRailtrac, faTrainDuo } from '@bds/fa-svg-icons';
import { Fastrac, FastracDefault, FastracGrid } from '@bds/models';
import { FastracBuilderService, FastracDefaultService } from '@bds/services';
import {
    faAddressBook,
    faChartNetwork,
    faCodeBranch,
    faCog,
    faCogs,
    faFireAlt,
    faFlag,
    faFlaskPotion,
    faHome,
    faMapMarkerAlt,
    faPaperclip,
    faRoute,
    faRss,
    faStar,
    faUsers,
    faWrench,
    faTrain,
    faFile,
    faFolderTree,
    faInfoSquare,
} from '@fortawesome/pro-duotone-svg-icons';
import { faGripLinesVertical } from '@fortawesome/pro-regular-svg-icons';
import { faCaretRight, faEllipsisVAlt } from '@fortawesome/pro-solid-svg-icons';
import { AsyncSubject, Observable, firstValueFrom } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ReferenceApplicationPathProvider } from '@bds/reference-pathing';
import { EquipmentApplicationPathProvider } from '@bds/equipment-pathing';

@Component({
    selector: 'rt-sidenav',
    templateUrl: './rt-sidenav.component.html',
    styleUrls: ['./rt-sidenav.component.scss'],
})
export class RtSidenavComponent implements OnInit, OnDestroy, OnChanges {
    FastracGrid = FastracGrid;
    caretRight = faCaretRight;
    defaultFastracs: FastracDefault[];
    fastracs: Fastrac[];
    jeopardizedShipmentFastracs: Fastrac[];
    defaultRouteFastrac: Fastrac;
    divertibleShipmentsFastrac: Fastrac;
    defaultTripFastrac: Fastrac;

    iconHome = faHome;
    iconClms = faRss;
    iconCommodities = faFlaskPotion;
    iconCustomers = faAddressBook;
    iconDiversions = faCodeBranch;
    iconEquipment = faWrench;
    iconEquipmentProfile = faCogs;
    iconFastrac = faFastrac;
    iconFavorites = faStar;
    iconFleetAssignment = faPaperclip;
    iconGear = faCog;
    iconGrip = faGripLinesVertical;
    iconJeopardizedShipments = faFlag;
    iconMore = faEllipsisVAlt;
    iconRecipients = faUsers;
    iconRoutes = faRoute;
    iconTrips = faTrainDuo;
    iconWorkflow = faChartNetwork;
    iconRailtrac = faRailtrac;
    iconOrigins = faMapMarkerAlt;
    iconAdmin = faCog;
    iconHotListGeneral = faFireAlt;
    iconRailroad = faTrain;
    iconFile = faFile;
    iconFolderTree = faFolderTree;
    iconInfoSquare = faInfoSquare;

    showReorder = true;
    sidenavOpened = false;

    private destroyer$ = new AsyncSubject<void>();
    private authenticationRetry = false;

    @Input() isAuthenticated = false;

    @Output() sectionChanged = new EventEmitter<string>();
    fastracs$: Observable<FastracDefault[]>;

    get fastracDefaults(): FastracDefault[] {
        return [...this.fastracDefaultService.userDefaults];
    }

    constructor(
        public fastracBuilderService: FastracBuilderService,
        public fastracDefaultService: FastracDefaultService,
        private router: Router,
        public referenceAppPathProvider: ReferenceApplicationPathProvider,
        public equipmentAppPathProvider: EquipmentApplicationPathProvider,
    ) {}

    ngOnInit() {
        if (this.isAuthenticated) {
            void this.onAuthentication();
            this.fastracs$ = this.fastracDefaultService.getFastracDefaults();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (
            changes.isAuthenticated &&
            !changes.isAuthenticated.firstChange &&
            this.isAuthenticated
        ) {
            void this.onAuthentication();
        }
        this.fastracs$ = this.fastracDefaultService.getFastracDefaults();
    }

    ngOnDestroy(): void {
        this.destroyer$.next(null);
        this.destroyer$.complete();
    }

    fastracFavListDrop(event: CdkDragDrop<any>): void {
        moveItemInArray(this.fastracs, event.previousIndex, event.currentIndex);
        for (let i = 0; i < this.fastracs.length; i++) {
            if (this.fastracs[i].favoriteOrder !== i + 1) {
                this.fastracBuilderService.setFavoritesOrder(this.fastracs[i].id, i + 1);
            }
        }
    }

    fastracFavListEntered(event: CdkDragEnter<any>): void {}

    fastracFavListExited(event: CdkDragExit<any>): void {}

    fastracFavListSorted(event: CdkDragSortEvent<any>): void {}

    getDefaultForRoute(childRoute: string): number {
        if (!childRoute || childRoute.indexOf('/') < 0) {
            return 0;
        }

        const page: string = childRoute.split('/')[1];
        const gridId = FastracGrid[page];
        const gridDefault = this.fastracDefaults.find((d) => d.gridId === gridId);

        if (gridDefault) {
            return gridDefault.fastracId;
        }

        return 0;
    }

    /**
     * populate fastrac menus on side nav
     */
    async onAuthentication(): Promise<void> {
        try {
            if (!this.fastracDefaults.length) {
                await firstValueFrom(this.fastracDefaultService.getFastracDefaults());
            }

            this.fastracBuilderService.fastracList
                .pipe(takeUntil(this.destroyer$))
                .subscribe((fastracs) => {
                    if (fastracs.length === 0) {
                        void firstValueFrom(this.fastracBuilderService.getAllFastracs());
                        return;
                    }

                    const routeGridId = FastracGrid['routes'];
                    this.defaultRouteFastrac = fastracs
                        .filter((x) => x.gridId === routeGridId && x.ownerId === 'BDS')
                        .find((x) => x.name === 'SYSTEM Route');

                    this.defaultTripFastrac = fastracs.find(
                        (x) => x.name === 'SYSTEM Trip' && x.ownerId === 'BDS',
                    );

                    this.fastracs = fastracs
                        .filter((f) => f.isFavorite)
                        .sort((a, b) => (a.favoriteOrder || 0) - (b.favoriteOrder || 0));

                    const jsGridId = FastracGrid['jeopardized'];
                    this.divertibleShipmentsFastrac = fastracs.find(
                        (x) => x.name === 'Template: Divertable Shipments',
                    );

                    const jsGridDefault = this.fastracDefaults.find((d) => d.gridId === jsGridId);
                    this.jeopardizedShipmentFastracs = [];

                    if (jsGridDefault) {
                        const defaultFastrac = fastracs.find(
                            (f) => f.id === jsGridDefault?.fastracId,
                        );
                        if (defaultFastrac) {
                            this.jeopardizedShipmentFastracs.push(defaultFastrac);
                        }
                    }

                    this.jeopardizedShipmentFastracs.push(
                        ...fastracs
                            .filter(
                                (f) =>
                                    f.gridId === jsGridId &&
                                    f.ownerId === 'BDS' &&
                                    f.id !== jsGridDefault?.fastracId,
                            )
                            .sort((a, b) =>
                                a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }),
                            ),
                    );
                });
        } catch (error) {
            if (!this.authenticationRetry) {
                this.authenticationRetry = true;
                console.error('Authentication failed, retrying:', error);
                await this.onAuthentication();
            }
        }
    }

    /**
     * On Fastrac Link Clicked
     * @param fastrac - fastrac item
     * @returns - void
     */
    onFastracLinkClicked(fastrac: Fastrac): void {
        if (!fastrac) {
            return;
        }

        const childRoute = '/' + FastracGrid[fastrac.gridId];
        const optionalParams = { fId: fastrac.id };

        this.onItemClicked(childRoute, optionalParams);
    }

    /**
     * On nav item clicked
     * @param childRoute
     * @param optionalParams
     * @param queryParams
     * @returns void
     */
    onItemClicked(childRoute: string, optionalParams?: object, queryParams?: object): void {
        if (!childRoute || childRoute.trim() === '/') {
            return;
        }

        if (!optionalParams) {
            optionalParams = {};
        }

        // Set up optional parameters, including fastracId
        if (!optionalParams['fId']) {
            const defaultId = this.getDefaultForRoute(childRoute);

            if (defaultId) {
                optionalParams['fId'] = defaultId;
            }
        }

        if (queryParams && Object.keys(queryParams).length) {
            optionalParams['queryParams'] = queryParams;
        }

        if (Object.keys(optionalParams).length) {
            void this.router.navigate([childRoute, optionalParams], {
                queryParams: optionalParams,
            });
        } else {
            void this.router.navigate([childRoute]);
        }
    }

    /**
     * On selection
     * @param section
     */
    onSectionChange(section: string): void {
        this.sectionChanged.emit(section);
    }

    /**
     * Side bar button clicked event emit
     * @param event
     */
    onSidebarMoreButtonClick(event: Event): void {
        event.stopPropagation();
    }

    /**
     * Get default fastrac
     * @param gridId
     * @returns number
     */
    getFastracDefault(gridId: number): number {
        const defaultItem: FastracDefault = this.fastracDefaults.find((d) => d.gridId === gridId);

        if (defaultItem) {
            return defaultItem.fastracId;
        }
        return 0;
    }
}
