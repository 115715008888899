import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import {
    AbstractControl,
    UntypedFormArray,
    UntypedFormControl,
    UntypedFormGroup,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { nameof } from '@bds/core';
import {
    BdsErpcOriginService,
    BdsSplcErpcService,
    BdsSupplierService,
    RtCarAssignService,
    RtCarMechService,
    RtRouteService,
} from '@bds/data-access';
import { BdsCustomerService } from '@bds/data-access';
import { EquipmentService } from '@bds/equipment';
import { FormErrorHandlerService } from '@bds/helpers';
import {
    RtBusinessGroup,
    RtErpcOrigin,
    RtFleetName,
    RtRouteCode,
    RtRouteCodeAdapter,
    RtSplcErpc,
    RtStateCode,
    RtSupplierFact,
    RtTrip,
    RtTripMetadata,
    RtTripRefFieldDef,
    RtTripRefFieldValue,
} from '@bds/railtrac-models';
import { RTSupplierFact, RtCustomerFact } from '@bds/reference-models';
import {
    BdsDialogMultiConfirmComponent,
    BdsDialogMultiConfirmModel,
    BdsSupplierSearchDialogModel,
    dxMatStyle,
} from '@bds/smart-components';
import {
    faArrowDown,
    faBell,
    faBellExclamation,
    faBellOn,
    faBellSlash,
    faClock,
    faCogs,
    faDollarSign,
    faExclamationTriangle,
    faFireAlt,
    faFlag,
    faQuestion,
} from '@fortawesome/pro-duotone-svg-icons';
import {
    faCircle,
    faCommentAltPlus,
    faDotCircle,
    faSearch,
} from '@fortawesome/pro-regular-svg-icons';
import { faEdit, faExternalLink } from '@fortawesome/pro-solid-svg-icons';
import { DxSelectBoxComponent } from 'devextreme-angular/ui/select-box';
import DataSource from 'devextreme/data/data_source';
import * as moment from 'moment';
import { Observable, Subject, Subscription, firstValueFrom } from 'rxjs';
import { concatMap, map, switchMap, take } from 'rxjs/operators';
import { BdsBusinessGroupService } from '../../bds-business-group/bds-business-group.service';
import { BdsFleetService } from '../../bds-fleet/bds-fleet.service';
import { BdsSightCodeService } from '../../bds-sight-code/bds-sight-code.service';
import { BdsRouteSearchDialogModel, BdsRouteSearchDialogComponent } from '@bds/route';
import { RtShipmentTypeService } from '../../rt-shipment-type/rt-shipment-type.service';
import { BdsStateService } from '@bds/data-access';
import { BdsCustomerDialogComponent, BdsCustomerDialogModel } from '@bds/customer';
import {
    BdsCustomerSearchDialogComponent,
    BdsCustomerSearchDialogModel,
} from '@bds/smart-components';
import { RailtracTripService } from '../railtrac-trip.service';
import { RtCustomerTypeDefaultService } from '@bds/data-access';
import { ReferenceApplicationPathProvider } from '@bds/reference-pathing';
import ODataStore from 'devextreme/data/odata/store';
import { BdsSupplierSearchDialogComponent } from '@bds/smart-components';
import { UpdateTripService } from '@bds/railtrac';
import { TripDateType } from '../../enum/tripDateType.enum';

@Component({
    selector: 'rt-trip-details',
    templateUrl: './railtrac-trip-details.component.html',
    styleUrls: ['./railtrac-trip-details.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class RailtracTripDetailsComponent implements OnInit, OnChanges, OnDestroy {
    get ormId(): number {
        return this.trip.ormId;
    }

    get isNew(): boolean {
        return !this.ormId;
    }

    get hasIssues(): boolean {
        return (
            this.isHotlistedComment ||
            this.isHotlistedGeneral ||
            this.isHotlistedMechanical ||
            this.isHotlistedLease ||
            this.isHotlistedTrouble ||
            !!this.jeopardyStatus
        );
    }

    get isHotlistedGeneral(): boolean {
        return this.trip.hotListFlag === 'Y';
    }

    get isHotlistedMechanical(): boolean {
        return this.trip.hotListFlagMechanical === 'Y';
    }

    get isHotlistedLease(): boolean {
        return this.trip.hotListFlagLease === 'Y';
    }

    get isHotlistedTrouble(): boolean {
        return this.trip.troubleYorN === 'Y';
    }

    get lastCommentCode(): string {
        return 'X';
    }

    get isHotlistedComment(): boolean {
        switch (this.lastCommentCode) {
            case 'SD':
            case 'H':
            case 'M':
            case 'L':
                return true;
            default:
                return false;
        }
    }

    get recordProcDate(): string {
        return this.trip?.procDateTime ? moment(this.trip.procDateTime).calendar() : '';
    }

    get interfaceValidationErrorCount(): number {
        return 0;
    }

    get isFreeRunner(): boolean {
        return this.tripForm && this.tripForm.controls[nameof<RtTrip>('freeRunner')].value !== 'N';
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    get dxMatStyle(): string {
        return dxMatStyle(this.matStyle);
    }

    @ViewChild('routeCodeSelectBox') routeCodeSelectBox;
    @Input() trip = new RtTrip();
    @Output() tripValuesChange = new EventEmitter<RtTrip>();

    @Input() tripId: number;
    @Input() tripRefFieldDefs: RtTripRefFieldDef[];
    @Input() allowChangeKey = false;
    @Input() allowPopout = false;
    @Input() allowAddComment = true;
    @Input() highlightDirty = false;
    @Input() multipleTrips = false;
    @Input() showWarnings = true;
    @Input() showMenu = true;
    @Input() readonly = false;
    @Input() matStyle: 'fill' | 'outline' | 'standard' | 'legacy' = 'fill';
    @Input() dense = true;

    @Input() dirty: boolean;
    @Output() dirtyChange = new EventEmitter<boolean>();
    @Output() tripUpdate = new EventEmitter<{ [key: string]: any }>();
    @Output() formValidChange = new EventEmitter<boolean>(true);

    // TODO: This doesn't appear to be used anywhere. If it is assigned in a parent in the future
    // or if it is in use, need to add dirty values of Trip Ref Data
    @Output() dirtyValues = new EventEmitter<Map<string, any> | any[] | any | undefined>();
    @Output() addComment = new EventEmitter<void>();
    @Output() equipmentChange: EventEmitter<number> = new EventEmitter();
    @Output() navigateClmSection = new EventEmitter();
    @Output() notifyToUpdateTrip: EventEmitter<any> = new EventEmitter<any>();

    private initialStatusCheckTrigger = new Subject<void>();

    canEditReturnCityState = false;
    returnCityDisplayValue = (data: RtErpcOrigin): string => (data ? `${data.erpcOrigin}` : '');
    busGrpSetFromEquipment = false;
    disabledFields = true;
    fleetSetFromEquipment = false;
    groupIndent = 'pl-0';
    headerSize = 'h3';
    matInputStyle = 'fill';
    matResultStyle = 'standard';
    matFloatLabel = 'always';

    iconEdit = faEdit;
    iconPopout = faExternalLink;
    iconAddComment = faCommentAltPlus;

    dirtyTripFormData: any;
    tripForm: UntypedFormGroup;
    tripFormValueChangeSubscription: Subscription;
    jeopardyStatus: string[];

    highlightDirtyOriginCity = false;

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //// Angular Form Support in DevExtreme
    //// https://js.devexpress.com/Documentation/Guide/Angular_Components/Component_Configuration_Syntax/#Angular_Forms_Support
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    customerFactDxLookupSource: DataSource;
    customerFactDxLookupSearchExpr: string[];
    customerFactDxLookupValueExpr: string;
    customerNoFieldServerName: string;
    customerNameFieldServerName: string;
    careOfNameFieldServerName: string;
    selectedCustomer: RtCustomerFact;
    selectedSupplier: RtSupplierFact;

    supplierFactDxLookupSource: DataSource;
    supplierCodeFieldServerName: string;
    supplierNameFieldServerName: string;
    supplierFactDxLookupValueExpr: string;
    supplierFactDxLookupSearchExpr: string[];

    routeCodeDxLookupSource: DataSource;
    erpcOriginDxLookupSource: ODataStore;
    routeCodeDxLookupSearchExpr: string[];
    routeCodeDxLookupValueExpr: string;
    routeCodeFieldServerName: string;
    erpcOriginServerName: string;
    routeDescriptionFieldServerName: string;
    selectedRoute: RtRouteCode;

    suppliers: RtSupplierFact[] = [];

    businessGroupDxLookupSource: DataSource;
    fleetDxLookupSource: DataSource;

    //// Trip Ref Information ////
    dirtyRefData: any;
    tripRefData: any;
    tripRefsValid: boolean;
    //////////////////

    ////// Temp //////
    @Output() mapLocationChange = new EventEmitter();
    @Output() mapViewReset = new EventEmitter();
    stateCodeLookup: Observable<RtStateCode[]>;
    sightCodeLookup: Observable<Array<any>>;
    shipmentTypeLookup: Observable<Array<any>>;
    //////////////////

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Shipment Alert Section //////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    hoverClass = 'mat-elevation-z8';
    iconJeopardy = faFlag;
    iconHotListGeneral = faFireAlt;
    iconHotListMechanical = faCogs;
    iconHotListLease = faDollarSign;
    iconHotListTrouble = faClock;
    iconCommentSD = faBellOn;
    iconCommentH = faBellExclamation;
    iconCommentM = faBell;
    iconCommentL1 = faBell;
    iconCommentL2 = faArrowDown;
    iconComment0 = faBellSlash;
    iconInterfaceValiationErrors = faExclamationTriangle;
    iconCircle = faCircle;
    iconDotCircle = faDotCircle;
    iconSearch = faSearch;
    iconUnknownEquipment = faQuestion;
    iconWarning = faExclamationTriangle;

    hideJeopardized = false;
    hideHotlistGeneral = false;
    hideHotlistMechanical = false;
    hideHotlistLease = false;
    hideHotlistTrouble = false;
    hideHotlistCommentCode = false;
    hideHotlistInterfaceErrors = false;
    showNewEquipmentMsg = false;
    showTripChangedFleet = false;
    showTripChangedBusGrp = false;
    isSetRoute = true;

    carStatusManuallyUpdated = false;
    shipmentTypeManuallyUpdated = false;

    public createAsLoadAndHold: boolean;

    @ViewChild('carNumSelectBox') carNumSelectBoxComponent: DxSelectBoxComponent;
    @ViewChild('routeCodeLookup') routeCodeLookupComponent: DxSelectBoxComponent;

    routeCodeSearchInlineButtonOptions = {
        type: 'default',
        icon: 'search',
        stylingMode: 'text',
        onClick: (): void => {
            this.searchForRouteCode();
        },
    };

    consigneeSearchInlineButtonOptions = {
        type: 'default',
        icon: 'search',
        stylingMode: 'text',
        onClick: (): void => {
            this.searchForConsignee();
        },
    };

    consigneeAddInlineButtonOptions = {
        type: 'default',
        icon: 'add',
        stylingMode: 'text',
        onClick: (): void => {
            //this.createConsignee();
            this.openConsigneeManager();
        },
    };

    consigneeEditInlineButtonOptions = {
        type: 'default',
        icon: 'edit',
        stylingMode: 'text',
        onClick: (): void => {
            //this.createConsignee(this.selectedCustomer);
            this.openConsigneeManager(this.selectedCustomer);
        },
    };

    routeAddInlineButtonOptions = {
        type: 'default',
        icon: 'add',
        stylingMode: 'text',
        onClick: (): void => {
            this.openRouteManager();
        },
    };

    routeEditInlineButtonOptions = {
        type: 'default',
        icon: 'edit',
        stylingMode: 'text',
        onClick: (): void => {
            this.openRouteManager(this.selectedRoute);
        },
    };

    supplierSearchInlineButtonOptions = {
        type: 'default',
        icon: 'search',
        stylingMode: 'text',
        onClick: (): void => {
            this.searchForSupplier();
        },
    };

    supplierAddInlineButtonOptions = {
        type: 'default',
        icon: 'add',
        stylingMode: 'text',
        onClick: (): void => {
            this.openSupplierManager();
        },
    };

    supplierEditInlineButtonOptions = {
        type: 'default',
        icon: 'edit',
        stylingMode: 'text',
        onClick: (): void => {
            this.openSupplierManager(this.selectedSupplier);
        },
    };

    constructor(
        public tripMetadata: RtTripMetadata,
        public stateApiService: BdsStateService,
        public supplierApiService: BdsSupplierService,
        public sightCodeService: BdsSightCodeService,
        public shipmentTypeService: RtShipmentTypeService,
        public customerApiService: BdsCustomerService,
        public customerTypeDefaultService: RtCustomerTypeDefaultService,
        public routeApiService: RtRouteService,
        public routeApiAdapter: RtRouteCodeAdapter,
        public businessGroupApiService: BdsBusinessGroupService,
        public fleetApiService: BdsFleetService,
        public tripService: RailtracTripService,
        public dialog: MatDialog,
        public snackbar: MatSnackBar,
        public equipmentService: EquipmentService,
        public carAssignService: RtCarAssignService,
        public carMechService: RtCarMechService,
        private formErrorService: FormErrorHandlerService,
        private originCodeService: BdsErpcOriginService,
        public referenceAppPathProvider: ReferenceApplicationPathProvider,
        public splcService: BdsSplcErpcService,
        public updateTripService: UpdateTripService,
    ) {
        this.tripForm = tripMetadata.getFormGroup();

        this.tripFormValueChangeSubscription = new Subscription();
        this.stateCodeLookup = stateApiService.getLookup();

        this.routeCodeFieldServerName = this.routeApiService.adapter.metadata.find(
            (f) => f.client === nameof<RtRouteCode>('routeCode'),
        ).server;
        this.routeDescriptionFieldServerName = this.routeApiService.adapter.metadata.find(
            (f) => f.client === nameof<RtRouteCode>('routeDscr'),
        ).server;

        this.erpcOriginServerName = this.originCodeService.adapter.metadata.find(
            (f) => f.client === nameof<RtErpcOrigin>('erpcOrigin'),
        ).server;

        this.customerNoFieldServerName = this.customerApiService.adapter.metadata.find(
            (f) => f.client === nameof<RtCustomerFact>('customerNo'),
        ).server;
        this.customerNameFieldServerName = this.customerApiService.adapter.metadata.find(
            (f) => f.client === nameof<RtCustomerFact>('customerName'),
        ).server;
        this.careOfNameFieldServerName = this.customerApiService.adapter.metadata.find(
            (f) => f.client === nameof<RtCustomerFact>('careOfName'),
        ).server;
        this.supplierCodeFieldServerName = this.supplierApiService.adapter.metadata.find(
            (f) => f.client === nameof<RtSupplierFact>('code'),
        ).server;
        this.supplierNameFieldServerName = this.supplierApiService.adapter.metadata.find(
            (f) => f.client === nameof<RtSupplierFact>('name'),
        ).server;

        this.triggerBasedOnTripInitiateStatus();
    }

    ngOnInit() {
        this.formValidChange?.emit(!!this.ormId);

        this.tripFormValueChangeSubscription.add(
            this.tripForm.valueChanges.subscribe((s) => {
                // Add Trip Ref fields
                const newTrip = this.addRefsToTrip();
                this.tripValuesChange.emit(newTrip);
                this.dirtyTripFormData = this.getDirtyValues(this.tripForm);
                this.formValidChange?.emit(this.tripForm?.valid);

                let allChanges;

                if (this.dirtyRefData) {
                    allChanges = new Map([...this.dirtyTripFormData, ...this.dirtyRefData]);
                } else {
                    allChanges = this.dirtyTripFormData;
                }

                this.dirtyValues.emit(allChanges);
                if (this.tripForm.dirty) {
                    this.dirtyChange.emit(this.tripForm.dirty);
                }
            }),
        );

        this.customerFactDxLookupSource = new DataSource({
            store: this.customerApiService.getODataStore(),
            searchExpr: [
                this.customerNoFieldServerName,
                this.customerNameFieldServerName,
                this.careOfNameFieldServerName,
            ],
            paginate: true,
            pageSize: 100,
        });

        this.supplierFactDxLookupSource = new DataSource({
            store: this.supplierApiService.getODataStore(),
            searchExpr: [this.supplierCodeFieldServerName, this.supplierNameFieldServerName],
            paginate: true,
            pageSize: 100,
        });

        this.customerFactDxLookupSearchExpr = [
            this.customerNoFieldServerName,
            this.customerNameFieldServerName,
            this.careOfNameFieldServerName,
        ];

        this.supplierFactDxLookupSearchExpr = [
            this.supplierCodeFieldServerName,
            this.supplierNameFieldServerName,
        ];

        const supplierStore = this.supplierApiService.getODataStore();
        if (supplierStore) {
            void supplierStore.load().then((x) => {
                this.suppliers = x;
            });
        }

        this.customerFactDxLookupValueExpr = this.customerNoFieldServerName;
        this.supplierFactDxLookupValueExpr = this.supplierCodeFieldServerName;

        this.businessGroupDxLookupSource = new DataSource({
            store: this.businessGroupApiService.getODataStore(),
            searchExpr: [
                this.businessGroupApiService.adapter.metadata.find(
                    (f) => f.client === nameof<RtBusinessGroup>('businessGroup'),
                ).server,
                this.businessGroupApiService.adapter.metadata.find(
                    (f) => f.client === nameof<RtBusinessGroup>('businessGroupName'),
                ).server,
            ],
            paginate: true,
            pageSize: 100,
        });

        this.fleetDxLookupSource = new DataSource({
            store: this.fleetApiService.getODataStore(),
            searchExpr: [
                this.fleetApiService.adapter.metadata.find(
                    (f) => f.client === nameof<RtFleetName>('fleetId'),
                ).server,
                this.fleetApiService.adapter.metadata.find(
                    (f) => f.client === nameof<RtFleetName>('fleetName'),
                ).server,
            ],
            paginate: true,
            pageSize: 100,
        });

        this.routeCodeDxLookupSource = new DataSource({
            store: this.routeApiService.getODataStore(),
            searchExpr: [this.routeCodeFieldServerName, this.routeDescriptionFieldServerName],
            paginate: true,
            pageSize: 100,
        });

        this.tripForm.controls[nameof<RtTrip>('routeCode')].valueChanges.subscribe((s: string) => {
            if (this.createAsLoadAndHold !== (s === 'LH')) {
                this.createAsLoadAndHold = s === 'LH';
                this.setValidators();
                this.toggleLoadHold(false);
            }

            if (!!this.ormId) {
                // : below logic is new trips only.
                return;
            }

            if (!s) {
                this.setRouteFields();
            } else {
                this.routeApiService.read(s).subscribe((selectedRoute) => {
                    this.setRouteFields(selectedRoute);
                });
            }

            void this.setCarStatus();
            this.toggleBasedOnTripInitiateStatus();
        });

        this.erpcOriginDxLookupSource = this.splcService.getODataStore();

        this.routeCodeDxLookupSearchExpr = [
            this.routeCodeFieldServerName,
            this.routeDescriptionFieldServerName,
        ];

        this.routeCodeDxLookupValueExpr = this.routeCodeFieldServerName;

        this.tripForm.controls[nameof<RtTrip>('businessGroup')].valueChanges.subscribe(() => {
            this.showTripChangedBusGrp = false;
            if (this.busGrpSetFromEquipment) {
                // : if this was set from a code-perspective, nothing to do here
                this.busGrpSetFromEquipment = false;
                return;
            }

            if (
                this.trip.ormId &&
                this.trip.businessGroup !==
                    this.tripForm.controls[nameof<RtTrip>('businessGroup')].value
            ) {
                this.showTripChangedBusGrp = true;
                return;
            }

            if (this.isFreeRunner) {
                // : only applies to known equipment
                return;
            }
        });

        this.tripForm.controls[nameof<RtTrip>('fleetId')].valueChanges.subscribe(() => {
            this.showTripChangedFleet = false;
            if (this.fleetSetFromEquipment) {
                // : if this was set from a code-perspective, nothing to do here
                this.fleetSetFromEquipment = false;
                return;
            }

            if (
                this.trip.ormId &&
                this.trip.fleetId !== this.tripForm.controls[nameof<RtTrip>('fleetId')].value
            ) {
                this.showTripChangedFleet = true;
                return;
            }

            if (this.isFreeRunner) {
                // : only applies to known equipment
                return;
            }
        });

        // this.tripForm.controls[nameof<RtTrip>('custNo')].valueChanges.subscribe((t) => {
        //     this.ChangeCustomerFields(t);
        // });

        //this.tripForm.controls[nameof<RtTrip>('tripStatus')].valueChanges.subscribe((t) => {
        //this.SetManualCloseParameters();
        //this.SetTripManualClose();
        //});

        this.tripForm.controls[nameof<RtTrip>('carStatus')].valueChanges.subscribe(() => {
            this.SetTripManualClose();
        });

        this.tripForm.controls[nameof<RtTrip>('originState')].valueChanges.subscribe(() => {
            this.SetMasterOrigin();
        });

        this.tripForm.controls[nameof<RtTrip>('shipDatetime')].valueChanges.subscribe(() => {
            if (!this.trip.ormId) {
                this.setDateFields();
            }
            // if (t) {
            //     var shipDate = new Date(t);
            //     shipDate.setUTCHours(0, 0, 0, 0);
            //     this.tripForm.controls[nameof<RtTrip>('shipDatetime')].setValue(shipDate);
            // }
        });

        // this.tripForm.controls[nameof<RtTrip>('supplierName')].valueChanges.subscribe(() => {
        //     const supplierName = this.tripForm.controls[nameof<RtTrip>('supplierName')].value;

        //     if (supplierName && this.suppliers) {
        //         const selectedSupplier = this.suppliers.find((x) => x.name === supplierName);

        //         this.tripForm.controls[nameof<RtTrip>('supplierCode')].setValue(
        //             selectedSupplier?.code ?? 'NOSUP',
        //         );
        //     }
        // });

        this.tripForm.controls[nameof<RtTrip>('loadEmpty')].valueChanges.subscribe(() => {
            void this.setCarStatus(true);
            void this.toggleBasedOnTripInitiateStatus();
        });

        this.tripForm.controls[nameof<RtTrip>('supplierCode')].valueChanges.subscribe((data) => {
            this.tripForm.controls[nameof<RtTrip>('supplierName')].enable();

            if (this.trip.ormId && data) {
                this.tripForm.controls[nameof<RtTrip>('supplierName')].disable();
            }
        });

        this.updateTripService.updateTripChangeEmitted$.subscribe((data) => {
            this.ApplyDateToTrip(data);
        });
    }

    ApplyDateToTrip(data: any): void {
        if (data) {
            const dateType = data.dateType;
            const dateString = data.dateValue as string;
            const localDate = new Date(dateString);
            const utcDate = new Date(
                localDate.getTime() - localDate.getTimezoneOffset() * 60000,
            ).toISOString();

            switch (dateType) {
                case TripDateType.ConsigneeNotificationDate:
                    this.tripForm.controls[nameof<RtTrip>('destinationArrDateTime')].setValue(
                        utcDate,
                    );
                    this.tripForm.controls[nameof<RtTrip>('destinationArrType')].setValue('A');
                    break;
                case TripDateType.ConsigneeDeliveryDate:
                    this.tripForm.controls[nameof<RtTrip>('customerDeliveryDateTime')].setValue(
                        utcDate,
                    );
                    this.tripForm.controls[nameof<RtTrip>('customerDeliveryType')].setValue('A');
                    break;
                case TripDateType.ConsigneeReleaseDate:
                    this.tripForm.controls[nameof<RtTrip>('customerReleaseDateTime')].setValue(
                        utcDate,
                    );
                    this.tripForm.controls[nameof<RtTrip>('customerReleaseType')].setValue('A');
                    break;
                case TripDateType.TripCloseDate:
                    this.tripForm.controls[nameof<RtTrip>('tripCloseDateTime')].setValue(utcDate);
                    this.tripForm.controls[nameof<RtTrip>('tripCloseType')].setValue('A');
                    break;
            }
            this.notifyToUpdateTrip.emit();
        }
    }

    navigateToSection(section: string): void {
        this.navigateClmSection.emit(section);
    }

    //Set Manual Close Parameters when user selects Trip Status = Close
    onTripStatusChange(event): void {
        if (this.trip.ormId) {
            //only applies for edit trips
            const tripStatus = event.value;

            if (tripStatus && tripStatus == 'C') {
                const confirmInfo: BdsDialogMultiConfirmModel = {
                    title: 'Confirm',
                    content: 'Do you wish to use this car for ETA calculations?',
                    actionText: 'Yes',
                    dismissiveText: 'No',
                };

                const dialogRef = this.dialog.open(BdsDialogMultiConfirmComponent, {
                    width: '400px',
                    data: confirmInfo,
                });

                dialogRef.afterClosed().subscribe((result) => {
                    this.trip.tripStatus = 'C';
                    const tripCloseTypeControl =
                        this.tripForm.controls[nameof<RtTrip>('tripCloseType')];
                    const etaManualCloseControl =
                        this.tripForm.controls[nameof<RtTrip>('etaManualClose')];
                    if (result) {
                        const tripCloseDateControl =
                            this.tripForm.controls[nameof<RtTrip>('tripCloseDateTime')];
                        tripCloseDateControl.setValue(new Date());
                        tripCloseTypeControl.setValue('A');
                        etaManualCloseControl.setValue(null);
                    } else {
                        tripCloseTypeControl.setValue('E');
                        etaManualCloseControl.setValue('Y');
                    }
                });
            }
        }
    }

    SetTripManualClose(): void {
        if (this.trip.ormId) {
            // : the logic after this only applies to edit trips
            return;
        }
        this.trip.etaManualClose = 'Y';
    }

    SetMasterOrigin(): void {
        if (!!this.trip.ormId) {
            // : the logic after this only applies to create Trips
            return;
        }
        const originCity = this.tripForm.controls[nameof<RtTrip>('originCity')].value;
        const originState = this.tripForm.controls[nameof<RtTrip>('originState')].value;

        if (originCity && originState) {
            this.originCodeService
                .getByCityState(originCity, originState)
                .pipe(take(1))
                .subscribe((oc) => {
                    // Had to put this as a separate function and call a second time
                    // Unable to use simple spread operator due to "from" property on RtArrAtOrig
                    if (oc && oc.originCode) {
                        this.tripForm.controls[nameof<RtTrip>('masterOrigin')].setValue(
                            oc.masterOriginYorn == 'Y' ? oc.originCode : '',
                        );
                    }
                });
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!!this.trip.ormId) {
            this.tripService.getJeopardyStatus(this.trip.ormId).subscribe((s) => {
                this.jeopardyStatus = s;
            });
            this.toggleBasedOnTripInitiateStatus();
        } else if (!this.multipleTrips) {
            this.trip.tripStatus = 'O';
            this.trip.divertYorN = 'N';
            this.trip.badOrderDays = 0;
            this.trip.freightPmtCode = 'P';
            this.trip.billDetentionYorN = 'Y';
        }
        this.disableControls();
        // const dirtyChange = changes['dirty'];
        // if (dirtyChange && !dirtyChange.currentValue) {
        //     this.tripForm.markAsPristine();
        // }

        if (changes['trip']) {
            this.tripForm.reset(this.trip);
            const shipmentTypeControl = this.tripForm.controls[nameof<RtTrip>('shipmentType')];
            shipmentTypeControl.setValue(this.trip.shipmentType);
        }

        if (this.multipleTrips && this.trip.isReturnCityEditable) {
            this.canEditReturnCityState = true;
            this.tripForm.controls[nameof<RtTrip>('returnCity')].enable();
            this.tripForm.controls[nameof<RtTrip>('returnState')].enable();
            this.tripForm.controls[nameof<RtTrip>('returnCity')].setValue(this.trip.returnCity);
            this.tripForm.controls[nameof<RtTrip>('returnState')].setValue(this.trip.returnState);
        }
    }

    ngOnDestroy() {
        this.tripFormValueChangeSubscription.unsubscribe();
    }

    private disableControls() {
        //Fields disabled during both add and edit trip
        this.tripForm.controls[nameof<RtTrip>('originCity')].disable();
        this.tripForm.controls[nameof<RtTrip>('originState')].disable();
        this.tripForm.controls[nameof<RtTrip>('destinationCity')].disable();
        this.tripForm.controls[nameof<RtTrip>('destinationState')].disable();
        this.tripForm.controls[nameof<RtTrip>('releaseCity')].disable();
        this.tripForm.controls[nameof<RtTrip>('releaseState')].disable();
        this.tripForm.controls[nameof<RtTrip>('returnCity')].disable();
        this.tripForm.controls[nameof<RtTrip>('returnState')].disable();
        this.tripForm.controls[nameof<RtTrip>('oneWayRound')].disable();
        this.tripForm.controls[nameof<RtTrip>('shopMove')].disable();
        this.tripForm.controls[nameof<RtTrip>('divertYorN')].disable();
        this.tripForm.controls[nameof<RtTrip>('masterOrigin')].disable();
        this.tripForm.controls[nameof<RtTrip>('masterTripNo')].disable();

        //fields disabled only during add
        if (!this.trip.ormId) {
            this.tripForm.controls[nameof<RtTrip>('freeRunner')].disable();
            this.tripForm.controls[nameof<RtTrip>('previousTripCloseDateTime')].disable();
            this.tripForm.controls[nameof<RtTrip>('originalEtaToCustomer')].disable();
            this.tripForm.controls[nameof<RtTrip>('originalEtaToOrigin')].disable();
            this.tripForm.controls[nameof<RtTrip>('tripCloseDateTime')].disable();
            this.tripForm.controls[nameof<RtTrip>('tripCloseType')].disable();
            this.tripForm.controls[nameof<RtTrip>('destinationArrDateTime')].disable();
            this.tripForm.controls[nameof<RtTrip>('customerReleaseDateTime')].disable();
            this.tripForm.controls[nameof<RtTrip>('customerDeliveryDateTime')].disable();
            this.tripForm.controls[nameof<RtTrip>('customerReleaseType')].disable();
            this.tripForm.controls[nameof<RtTrip>('carStatus')].disable();
            this.tripForm.controls[nameof<RtTrip>('destinationArrType')].disable();
            this.tripForm.controls[nameof<RtTrip>('customerDeliveryType')].disable();
        }

        //fields disabled only during edit
        if (this.trip.ormId) {
            this.tripForm.controls[nameof<RtTrip>('customerNo')].disable();
            this.tripForm.controls[nameof<RtTrip>('routeCode')].disable();
            this.tripForm.controls[nameof<RtTrip>('loadEmpty')].disable();
        }

        if (this.multipleTrips) {
            this.tripForm.controls[nameof<RtTrip>('loadEmpty')].disable();
            this.tripForm.controls[nameof<RtTrip>('routeCode')].disable();
            this.tripForm.controls[nameof<RtTrip>('customerNo')].disable();
            this.tripForm.controls[nameof<RtTrip>('tripStatus')].disable();
            this.tripForm.controls[nameof<RtTrip>('carStatus')].disable();
            this.tripForm.controls[nameof<RtTrip>('shipDatetime')].disable();
            this.tripForm.controls[nameof<RtTrip>('destinationArrType')].disable();
            this.tripForm.controls[nameof<RtTrip>('customerDeliveryType')].disable();
            this.tripForm.controls[nameof<RtTrip>('customerReleaseType')].disable();
            this.tripForm.controls[nameof<RtTrip>('destinationArrDateTime')].disable();
            this.tripForm.controls[nameof<RtTrip>('customerReleaseDateTime')].disable();
            this.tripForm.controls[nameof<RtTrip>('customerDeliveryDateTime')].disable();
        }
    }

    private setRouteFields(selectedRoute?: RtRouteCode) {
        this.selectedRoute = selectedRoute;
        const empty = !selectedRoute;

        const setField = (fieldName, value) => {
            const field = this.tripForm.controls[fieldName];
            field.setValue(empty ? '' : value);

            if (!field.value) {
                field.enable();
            } else {
                field.disable();
            }
        };

        this.tripForm.controls[nameof<RtTrip>('destinationCity')].setValue(
            empty ? '' : selectedRoute.destCity,
        );
        this.tripForm.controls[nameof<RtTrip>('destinationState')].setValue(
            empty ? '' : selectedRoute.destState,
        );

        this.tripForm.controls[nameof<RtTrip>('originCity')].setValue(
            empty ? '' : selectedRoute.originCity,
        );
        this.tripForm.controls[nameof<RtTrip>('originState')].setValue(
            empty ? '' : selectedRoute.originState,
        );

        this.tripForm.controls[nameof<RtTrip>('releaseCity')].setValue(
            empty ? '' : selectedRoute.releaseCity,
        );
        this.tripForm.controls[nameof<RtTrip>('releaseState')].setValue(
            empty ? '' : selectedRoute.releaseState,
        );

        this.tripForm.controls[nameof<RtTrip>('returnCity')].setValue(
            empty ? '' : selectedRoute.returnCity,
        );
        this.tripForm.controls[nameof<RtTrip>('returnState')].setValue(
            empty ? '' : selectedRoute.returnState,
        );

        this.tripForm.controls[nameof<RtTrip>('oneWayRound')].setValue(
            empty ? '' : selectedRoute.oneWayRound,
        );

        if (!empty) {
            const tripStatusControl = this.tripForm.controls[nameof<RtTrip>('tripStatus')];
            const carStatusControl = this.tripForm.controls[nameof<RtTrip>('carStatus')];
            //when RouteCode = 'LH, then trip Status = C and car status = 0
            if (this.selectedRoute.routeCode == 'LH') {
                tripStatusControl.setValue('C');
                carStatusControl.setValue('0');
                carStatusControl.disable(); // do not allow user to override
            } else if (this.selectedRoute.autoCloseYorn == 'Y') {
                tripStatusControl.setValue('C');
                !this.multipleTrips ? carStatusControl.enable() : carStatusControl.disable();
            } else {
                tripStatusControl.setValue('O');
                !this.multipleTrips ? carStatusControl.enable() : carStatusControl.disable();
            }

            //Set all types to estimate
            this.tripForm.controls[nameof<RtTrip>('destinationArrType')].setValue('E');
            this.tripForm.controls[nameof<RtTrip>('customerDeliveryType')].setValue('E');
            this.tripForm.controls[nameof<RtTrip>('customerReleaseType')].setValue('E');
            this.tripForm.controls[nameof<RtTrip>('tripCloseType')].setValue('E');
            this.setDateFields();
        }
    }

    setDateFields(): void {
        const shipmentDate = this.tripForm.controls[nameof<RtTrip>('shipDatetime')].value;
        if (this.selectedRoute && shipmentDate) {
            const shipmentTime: number = new Date(shipmentDate).getTime();
            const consigneeNotification = new Date(
                shipmentTime +
                    (this.createAsLoadAndHold ? 0 : this.selectedRoute?.etaHrsOut ?? 0) *
                        60 *
                        60 *
                        1000,
            );
            //Consignee Notification (EST):  Ship Date Time + eta_hours_out from RT_ROUTE_CODE
            this.tripForm.controls[nameof<RtTrip>('destinationArrDateTime')].setValue(
                consigneeNotification,
            );
            //Consignee Delivery (EST): Ship Date Time + eta_hours_out from RT_ROUTE_CODE
            this.tripForm.controls[nameof<RtTrip>('customerDeliveryDateTime')].setValue(
                consigneeNotification,
            );
            if (this.selectedCustomer || this.createAsLoadAndHold) {
                const releaseTime: number = new Date(consigneeNotification).getTime();
                const releaseDate = new Date(
                    releaseTime +
                        (this.createAsLoadAndHold ? 0 : this.selectedCustomer.customerRequiredHrs) *
                            60 *
                            60 *
                            1000,
                );
                //Consignee Release Date (EST):  estimated Consignee notification/delivery + cust_req_hrs from RT_CUSTOMER_FACT
                this.tripForm.controls[nameof<RtTrip>('customerReleaseDateTime')].setValue(
                    releaseDate,
                );
                const closeDate = new Date(
                    releaseTime +
                        (this.createAsLoadAndHold ? 0 : this.selectedRoute.etaHrsOut ?? 0) *
                            60 *
                            60 *
                            1000,
                );
                //Trip Close Date (EST): estimated Consignee Release Date + eta_hours_in from RT_ROUTE_CODE
                this.tripForm.controls[nameof<RtTrip>('tripCloseDateTime')].setValue(closeDate);
            }
        }
    }

    toggleLoadHold(setRouteCode = true): void {
        if (setRouteCode) {
            this.tripForm.controls[nameof<RtTrip>('routeCode')].setValue(
                this.createAsLoadAndHold ? 'LH' : null,
            );
        }
        this.tripForm.controls[nameof<RtTrip>('freightPmtCode')].setValue(
            this.createAsLoadAndHold ? 'P' : null,
        );

        if (!this.createAsLoadAndHold) {
            this.selectedRoute = new RtRouteCode();
        } else {
            //If Shipment is Load and Hold, set L/E to L
            this.tripForm.controls[nameof<RtTrip>('loadEmpty')].setValue('L');
        }

        if (!this.trip.ormId) {
            const originCity = this.tripForm.controls[nameof<RtTrip>('originCity')];
            const originState = this.tripForm.controls[nameof<RtTrip>('originState')];
            originCity.enable();
            originState.enable();
            this.highlightDirtyOriginCity = false;

            const shipmentTypeControl = this.tripForm.controls[nameof<RtTrip>('shipmentType')];
            shipmentTypeControl.setValue(this.createAsLoadAndHold ? '16' : null);

            if (!this.createAsLoadAndHold) {
                originCity.disable();
                originState.disable();
                this.highlightDirtyOriginCity = this.highlightDirty;
            }
        }

        this.setValidators();
    }

    private setValidators() {
        this.tripMetadata.setValidators(this.tripForm, this.createAsLoadAndHold, this.trip.ormId);
        this.tripForm.updateValueAndValidity();
    }

    routeCodeDxLookupDisplayExpr = (route: any) => {
        if (!route) {
            return '';
        }

        if (this.isSetRoute) {
            this.setValidators();
        }
        this.isSetRoute = false;

        const routeCode = route[this.routeCodeFieldServerName];
        const routeDscr = route[this.routeDescriptionFieldServerName];
        return `${routeCode} - ${routeDscr}`;
    };

    customerFactDxLookupDisplayExpr = (cust: any) => {
        if (!cust) {
            return '';
        }
        const custNo = cust[this.customerNoFieldServerName];
        const custName = cust[this.customerNameFieldServerName];
        const careOfName = cust[this.careOfNameFieldServerName];
        return `${custNo} - ${custName}`;
    };

    supplierFactDxLookupDisplayExpr = (sup: any) => {
        if (!sup) {
            return '';
        }
        const supCode = sup[this.supplierCodeFieldServerName];
        const supName = sup[this.supplierNameFieldServerName];
        return `${supName} - ${supCode}`;
    };

    supplierFactInputDxLookupDisplayExpr = (sup: any) => {
        if (!sup) {
            return '';
        }
        const supCode = sup[this.supplierCodeFieldServerName];
        return `${supCode}`;
    };

    addRefsToTrip(): RtTrip {
        const newTrip: RtTrip = this.tripForm.value;

        Object.keys(this.tripRefData || {}).forEach((key) => {
            newTrip[key] = this.tripRefData[key];
        });

        return newTrip;
    }

    getRefFieldLabel(key: string): string {
        if (this.tripRefFieldDefs && this.tripRefFieldDefs.length) {
            const def: RtTripRefFieldDef = this.tripRefFieldDefs.filter(
                (d) => d.tripRefField === key,
            )[0];
            return def.displayName || key;
        }

        return key;
    }

    getRefFieldValues(key: string): RtTripRefFieldValue[] {
        if (this.tripRefFieldDefs && this.tripRefFieldDefs.length) {
            const def: RtTripRefFieldDef = this.tripRefFieldDefs.filter(
                (d) => d.tripRefField === key,
            )[0];
            return def.rtTripFieldValues || [];
        }

        return [];
    }

    /**
     * Get error text based on the validation of the specified form item.
     * @param formItem The item on a form that has validation.
     */
    getErrorText(formItem: UntypedFormControl | UntypedFormGroup | UntypedFormArray): string {
        return this.formErrorService.getFormError(formItem);
    }

    kvpDisplayTemplate(key: string, value: string): string {
        const split = !!key && !!value ? ' - ' : '';
        return `${key}${split}${value}`;
    }

    private estActButtonClick = (e, control: UntypedFormControl, mode: 'A' | 'E') => {
        control.setValue(mode);
    };

    onRouteCodeChange(e): void {
        this.selectedRoute = this.routeApiAdapter.adapt(e.selectedItem);
    }

    /**
     * Handler for onCustomItemCreating on a dxSelectBox where the value is just text.
     * @param e The Event.
     */
    onDxSelectBoxCustomItemUpper(e): void {
        e.customItem = `${e.text || ''}`.toUpperCase();
    }

    onEquipmentNumberChanged(e: any): void {
        const carInit = this.tripForm.controls[nameof<RtTrip>('carInit')].value;
        const carNo = this.tripForm.controls[nameof<RtTrip>('carNo')].value;

        this.equipmentService.getEquipmentInfo(carInit, carNo).subscribe((s) => {
            if (!!s && Array.isArray(s) && s.length > 0) {
                this.tripForm.controls[nameof<RtTrip>('tcmEquipmentOrmId')].setValue(s[0].ormId);
                this.equipmentChange.emit(s[0].ormId);
                this.showNewEquipmentMsg = false;
                this.fetchCarAssignInfo();
            } else {
                this.tripForm.controls[nameof<RtTrip>('tcmEquipmentOrmId')].setValue(0);
                this.equipmentChange.emit(null);
                this.showNewEquipmentMsg = true;

                this.tripForm.controls[nameof<RtTrip>('freeRunner')].setValue('Y');
                this.tripForm.controls[nameof<RtTrip>('oneWayRound')].setValue('O');
                this.showTripChangedBusGrp = false;
                this.showTripChangedFleet = false;
            }
        });
    }

    async fetchCarAssignInfo(): Promise<void> {
        const init = this.tripForm.controls[nameof<RtTrip>('carInit')].value || ('' as string);
        const no = this.tripForm.controls[nameof<RtTrip>('carNo')].value || ('' as string);

        this.busGrpSetFromEquipment = true;
        this.fleetSetFromEquipment = true;

        this.tripForm.controls[nameof<RtTrip>('freeRunner')].setValue('Y');
        this.tripForm.controls[nameof<RtTrip>('oneWayRound')].setValue('O');
        try {
            const carAssignResult = await firstValueFrom(this.carAssignService.read(init, no));

            if (carAssignResult) {
                this.tripForm.controls[nameof<RtTrip>('businessGroup')].setValue(
                    carAssignResult?.businessGroup,
                );
                this.tripForm.controls[nameof<RtTrip>('fleetId')].setValue(carAssignResult.fleetId);

                this.tripForm.controls[nameof<RtTrip>('freeRunner')].setValue('N');
                this.tripForm.controls[nameof<RtTrip>('oneWayRound')].setValue('R');
                return;
            }
        } catch (error) {
            const carMechResult = await firstValueFrom(this.carMechService.read(init, no));

            if (carMechResult) {
                this.tripForm.controls[nameof<RtTrip>('businessGroup')].setValue(
                    carMechResult?.businessGroup,
                );
                this.tripForm.controls[nameof<RtTrip>('fleetId')].setValue(carMechResult?.fleetId);

                this.tripForm.controls[nameof<RtTrip>('freeRunner')].setValue('N');
                this.tripForm.controls[nameof<RtTrip>('oneWayRound')].setValue('R');
                return;
            }
        }

        this.tripForm.controls[nameof<RtTrip>('businessGroup')].setValue(null);
        this.tripForm.controls[nameof<RtTrip>('fleetId')].setValue(null);
        this.tripForm.controls[nameof<RtTrip>('freeRunner')].setValue('Y');
        this.tripForm.controls[nameof<RtTrip>('oneWayRound')].setValue('O');

        this.showTripChangedBusGrp = false;
        this.showTripChangedFleet = false;
    }

    onAdditionalRefsChanged(e: { model: any; isValid: boolean; changes: any }): void {
        this.tripRefsValid = e.isValid;
        this.tripRefData = e.model;
        this.dirtyRefData = e.changes;

        // Add on the rtTripRefData model to this.tripForm.value and call tripChange
        const newTrip = this.addRefsToTrip();
        this.tripValuesChange.emit(newTrip);

        if (this.dirtyRefData) {
            let allChanges;

            if (this.dirtyTripFormData) {
                allChanges = new Map([...this.dirtyTripFormData, ...this.dirtyRefData]);
            } else {
                allChanges = this.dirtyRefData;
            }

            this.dirtyValues.emit(allChanges);
        }
    }

    onCustomerFactChanged(e: any): void {
        if (!!this.trip.ormId) {
            //Only applies to edit trip
        } else if (e) {
            //Only applies to new trip
            this.ChangeCustomerFields(e.value);
        }
        //if trip initiated Status = 1, then allow user to edit Return City/State
        this.toggleBasedOnTripInitiateStatus();
    }

    private triggerBasedOnTripInitiateStatus() {
        this.initialStatusCheckTrigger
            .pipe(concatMap(() => this.getTripInitiateStatus()))
            .subscribe({
                next: (tripInitiateStatus: string) => {
                    if (!this.trip.ormId) {
                        // Applicable for both add and edit trip
                        this.processTripInitiateThree(tripInitiateStatus);
                    } else {
                        // Only for Edit trip
                        if (tripInitiateStatus === '1') {
                            this.processTripInitiateOne(tripInitiateStatus);
                        } else if (tripInitiateStatus === '3') {
                            this.canEditReturnCityState = false;
                            this.tripForm.controls[nameof<RtTrip>('returnCity')].disable();
                            this.tripForm.controls[nameof<RtTrip>('returnState')].disable();
                            // Applicable for both add and edit trip
                            this.processTripInitiateThree(tripInitiateStatus);
                        }
                    }
                },
                error: (err) => {
                    console.error('Error while getting getTripInitiateStatus():', err);
                },
            });
    }

    private toggleBasedOnTripInitiateStatus() {
        this.initialStatusCheckTrigger.next();
    }

    private processTripInitiateOne(tripInitiateStatus: string) {
        if (tripInitiateStatus === '1') {
            this.canEditReturnCityState = true;
            this.tripForm.controls[nameof<RtTrip>('returnCity')].enable();
            this.tripForm.controls[nameof<RtTrip>('returnState')].enable();
            this.tripForm.controls[nameof<RtTrip>('returnCity')].setValue(this.trip.returnCity);
            this.tripForm.controls[nameof<RtTrip>('returnState')].setValue(this.trip.returnState);
        } else {
            this.canEditReturnCityState = false;
            this.tripForm.controls[nameof<RtTrip>('returnCity')].disable();
            this.tripForm.controls[nameof<RtTrip>('returnState')].disable();
        }
    }

    private processTripInitiateThree(tripInitiateStatus: string) {
        if (tripInitiateStatus === '3') {
            //If trip initiated Status = 3, then return city/state should be same as destination city/state
            const destCity = this.tripForm.controls[nameof<RtTrip>('destinationCity')]
                .value as string;
            const destState = this.tripForm.controls[nameof<RtTrip>('destinationState')]
                .value as string;
            this.tripForm.controls[nameof<RtTrip>('returnCity')].setValue(destCity);
            this.tripForm.controls[nameof<RtTrip>('returnState')].setValue(destState);
        } else {
            //If trip initiated Status <> 3, then return city/state should be same as origin city/state
            const originCity = this.tripForm.controls[nameof<RtTrip>('originCity')].value as string;
            const originState = this.tripForm.controls[nameof<RtTrip>('originState')]
                .value as string;
            this.tripForm.controls[nameof<RtTrip>('returnCity')].setValue(originCity);
            this.tripForm.controls[nameof<RtTrip>('returnState')].setValue(originState);
        }
    }

    async onSupplierFactChanged(e: any): Promise<void> {
        const a = await firstValueFrom(this.supplierApiService.getSupplierByCode(e?.value));

        if (a?.length) {
            this.tripForm.controls[nameof<RtTrip>('supplierName')].setValue(a[0]?.name ?? '');
            return;
        }
        this.tripForm.controls[nameof<RtTrip>('supplierName')].reset();
    }

    searchForRouteCode(): void {
        this.routeCodeSelectBox.instance.close();
        const data = new BdsRouteSearchDialogModel();
        data.actionText = 'Select Route';
        data.title = 'Select Route';

        const dialogRef = this.dialog.open(BdsRouteSearchDialogComponent, {
            data: data,
        });
        dialogRef.afterClosed().subscribe((s: RtRouteCode) => {
            if (!!s) {
                this.tripForm.controls[nameof<RtTrip>('routeCode')].setValue(s.routeCode);
            }
        });
    }

    searchForConsignee(): void {
        const data = new BdsCustomerSearchDialogModel();
        data.actionText = 'Select Consignee';
        data.title = 'Select Consignee';
        data.source = this.customerFactDxLookupSource;
        data.defaultFastracName = 'SEARCH CONSIGNEE';

        const dialogRef = this.dialog.open(BdsCustomerSearchDialogComponent, {
            data: data,
        });
        dialogRef.afterClosed().subscribe((s: RtCustomerFact) => {
            if (!!s) {
                this.tripForm.controls[nameof<RtTrip>('customerNo')].setValue(s.customerNo);
            }
        });
    }

    searchForSupplier(): void {
        const data = new BdsSupplierSearchDialogModel();
        data.actionText = 'Select Supplier';
        data.title = 'Select Supplier';
        data.source = this.supplierFactDxLookupSource;
        data.defaultFastracName = 'SEARCH SUPPLIER';
        const dialogRef = this.dialog.open(BdsSupplierSearchDialogComponent, {
            data: data,
        });
        dialogRef.afterClosed().subscribe((s: RTSupplierFact) => {
            if (s) {
                this.tripForm.controls[nameof<RtTrip>('supplierCode')].setValue(s.code);
            }
        });
    }

    createConsignee(item?: RtCustomerFact): void {
        const data: BdsCustomerDialogModel = {
            title: item ? 'Edit Consignee' : 'Create Consignee',
            actionText: item ? 'Edit Consignee' : 'Create Consignee',
            action: item ? 'edit' : 'create',
            item: item,
        };
        const dialogRef = this.dialog.open(BdsCustomerDialogComponent, {
            data: data,
        });
        dialogRef.afterClosed().subscribe((s) => {
            if (!!s) {
                this.tripForm.controls[nameof<RtTrip>('customerNo')].setValue(s.customerNo);
            }
        });
    }

    addCommentClicked(): void {
        this.addComment.emit();
    }

    isArray(obj: any): boolean {
        return Array.isArray(obj);
    }

    // Source: https://stackoverflow.com/a/56288698/895646
    getDirtyValues(
        form: UntypedFormGroup | UntypedFormArray | UntypedFormControl | AbstractControl,
    ): Map<string, any> | any[] | any | undefined {
        if (!form.dirty) {
            return;
        }

        if (form instanceof UntypedFormControl) {
            return form.value;
        }

        if (form instanceof UntypedFormGroup) {
            const result = new Map();
            for (const [key, control] of Object.entries(form.controls)) {
                const nestedResult = this.getDirtyValues(control);
                if (nestedResult) {
                    result.set(key, this.getDirtyValues(control));
                }
            }

            return result;
        }

        if (form instanceof UntypedFormArray) {
            const result = new Array();
            form.controls.forEach((control) => {
                const nestedResult = this.getDirtyValues(control);
                if (nestedResult) {
                    result.push(nestedResult);
                }
            });

            return result;
        }

        throw new Error('Form must be a FromGroup, FormArray or FormControl.');
    }

    /////// TEMPORARY ///////
    clickDismissJeopardized(): void {
        this.hideJeopardized = true;
    }

    clickDismissHotlistGeneral(): void {
        this.hideHotlistGeneral = true;
    }

    clickDismissHotlistMechanical(): void {
        this.hideHotlistMechanical = true;
    }

    clickDismissHotlistLease(): void {
        this.hideHotlistLease = true;
    }

    clickDismissHotlistTrouble(): void {
        this.hideHotlistTrouble = true;
    }

    clickDismissHotlistCommentCode(): void {
        this.hideHotlistCommentCode = true;
    }

    clickDismissHotlistInterfaceErrors(): void {
        this.hideHotlistInterfaceErrors = true;
    }

    clickDismissNewEquipment(): void {
        this.showNewEquipmentMsg = false;
    }

    updateMapLocation(el): void {
        this.mapLocationChange.emit(el.path[0].value);
    }

    resetMapLocation(el): void {
        this.mapViewReset.emit(true);
    }

    clickDismissNewTripChangedFleet(): void {
        this.showTripChangedFleet = false;
    }

    clickDismissNewTripChangedBusGrp(): void {
        this.showTripChangedBusGrp = false;
    }

    ChangeCustomerFields(t: string): void {
        this.customerApiService.read(t).subscribe((selectedCustomer) => {
            const shopMoveControl = this.tripForm.controls[nameof<RtTrip>('shopMove')];
            const shopYorN = selectedCustomer && selectedCustomer.customerType == 'S' ? 'Y' : 'N';
            shopMoveControl.setValue(shopYorN);

            if (selectedCustomer) {
                this.selectedCustomer = selectedCustomer;
                //change shipment related dates when customer changes
                this.setDateFields();

                void this.setCarStatus();
            }
        });
    }

    displayValue = (data: RtErpcOrigin): string => (data ? `${data.erpcOrigin}` : '');

    manualUpdate(): void {
        this.carStatusManuallyUpdated = true;
        this.shipmentTypeManuallyUpdated = true;
    }

    private async setCarStatus(isLE = false) {
        const loadEmptyControl = this.tripForm.controls[nameof<RtTrip>('loadEmpty')];
        if (this.ormId) {
            return;
        }

        const customerTypes = await firstValueFrom(
            this.customerTypeDefaultService.getCustomerTypeDefaults(
                this.selectedCustomer?.customerType,
            ),
        );

        if (customerTypes) {
            const carStatusControl = this.tripForm.controls[nameof<RtTrip>('carStatus')];
            const shipmentTypeControl = this.tripForm.controls[nameof<RtTrip>('shipmentType')];
            const oneWayRoundControl = this.tripForm.controls[nameof<RtTrip>('oneWayRound')];

            !this.multipleTrips ? carStatusControl.enable() : carStatusControl.disable();

            carStatusControl.setValue(carStatusControl.value ?? '1');
            shipmentTypeControl.setValue(shipmentTypeControl.value ?? '01');

            if (!customerTypes.length) {
                return;
            }

            if (customerTypes.length == 1) {
                const custType = customerTypes[0];
                if (loadEmptyControl.value !== (custType.loadedEmptyIndicator ?? 'L') && !isLE) {
                    loadEmptyControl.setValue(custType.loadedEmptyIndicator ?? 'L');
                }

                carStatusControl.setValue(
                    this.carStatusManuallyUpdated
                        ? carStatusControl.value ??
                              (custType.beginStatus ? custType.beginStatus : '1')
                        : custType.beginStatus
                        ? custType.beginStatus
                        : '1',
                );
                //if trip has shiptype already selected then do not override based on customer type
                if (!(this.trip && this.trip.shipmentType)) {
                    shipmentTypeControl.setValue(
                        this.shipmentTypeManuallyUpdated
                            ? shipmentTypeControl.value ??
                                  (custType.shipType ? custType.shipType : '01')
                            : custType.shipType
                            ? custType.shipType
                            : '01',
                    );
                }
                oneWayRoundControl.setValue(
                    oneWayRoundControl.value ?? (custType.beginStatus == '3' ? 'O' : 'R'),
                );

                return;
            }

            const custType = customerTypes.filter(
                (data) => data?.loadedEmptyIndicator === loadEmptyControl?.value,
            );

            carStatusControl.setValue(
                this.carStatusManuallyUpdated
                    ? carStatusControl.value ?? (custType[0] ? custType[0]?.beginStatus : '1')
                    : custType[0]
                    ? custType[0]?.beginStatus
                    : '1',
            );
            if (!(this.trip && this.trip.shipmentType)) {
                shipmentTypeControl.setValue(
                    this.shipmentTypeManuallyUpdated
                        ? shipmentTypeControl.value ?? (custType[0] ? custType[0]?.shipType : '01')
                        : custType[0]
                        ? custType[0]?.shipType
                        : '01',
                );
            }
        }
    }

    openConsigneeManager(item?: RtCustomerFact): void {
        if (item) {
            this.referenceAppPathProvider
                .Customer(item.customerNo)
                .pipe(take(1))
                .subscribe((refUrl) => {
                    window.open(refUrl, '_blank');
                });
        } else {
            this.referenceAppPathProvider
                .Customers()
                .pipe(take(1))
                .subscribe((refUrl) => {
                    window.open(refUrl, '_blank');
                });
        }
    }

    openSupplierManager(item?: RtSupplierFact): void {
        if (item) {
            // this.referenceAppPathProvider
            //     .Supplier(item?.code)
            //     .pipe(take(1))
            //     .subscribe((refUrl) => {
            //         window.open(refUrl, '_blank');
            //     });
        } else {
            // this.referenceAppPathProvider
            //     .Suppliers()
            //     .pipe(take(1))
            //     .subscribe((refUrl) => {
            //         window.open(refUrl, '_blank');
            //     });
        }
    }

    openRouteManager(item?: RtRouteCode): void {
        if (item) {
            window.open('routes/detail/' + item.routeCode, '_blank').focus();
        } else {
            window.open('routes/create', '_blank').focus();
        }
    }

    onOriginCitySelected(erpc: RtErpcOrigin): void {
        if (erpc) {
            this.tripForm.controls[nameof<RtTrip>('originState')].setValue(erpc.erpcState);
        }
    }

    onReturnCitySelected(splc: RtErpcOrigin): void {
        if (splc) {
            setTimeout(() => {
                this.tripForm.controls[nameof<RtTrip>('returnCity')].setValue(splc.erpcOrigin);
                this.tripForm.controls[nameof<RtTrip>('returnState')].setValue(splc.erpcState);
            }, 0);
        }
    }
    /** Method to get the trip initiate status.Get the begin status of the customer type based on the customer
     * of the trip.
     *  */
    getTripInitiateStatus(): Observable<string> {
        var custNumber: string = this.tripForm.controls[nameof<RtTrip>('customerNo')].value;
        var le: string = this.tripForm.controls[nameof<RtTrip>('loadEmpty')].value;
        //Get Trip customer
        return this.customerApiService.getByCustNo(custNumber).pipe(
            switchMap((customer) => {
                //Get customer type for the customer
                return this.customerTypeDefaultService
                    .getByCustTypeAndLE(customer?.customerType, le)
                    .pipe(
                        map((customerType) => {
                            return customerType ? customerType.beginStatus : '';
                        }),
                    );
            }),
        );
    }
}
