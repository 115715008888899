import { RtCommodityShippedAdapter } from './rt-commodity-shipped';
import { RtCommodityShipped } from './rt-commodity-shipped';
import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { IColumnDescription, GridColumnDescription, FormGroupDescription } from './adapter_t';
import { nameof, TwoWayAdapter, IModelMetadata } from '@bds/core';

export interface PartialTripInfo {
    ormId?: number;
    carInit?: string;
    carNo?: string;
    isFreeRunner?: boolean;
    tripStatus?: string;
}

export interface RtTripUK {
    carInit?: string;
    carNo?: string;
    shipDatetime?: Date;
    ormId?: number;
    tcmEquipmentOrmId?: number;
    tripCloseDateTime?: Date;
}
export class RtTrip implements RtTripUK {
    constructor(
        public carInit?: string,
        public carNo?: string,
        public shipDatetime?: Date,
        public customerNo?: string,
        public routeCode?: string,
        public tripNo?: string,
        public originCity?: string,
        public originState?: string,
        public destinationCity?: string,
        public destinationState?: string,
        public releaseCity?: string,
        public releaseState?: string,
        public returnCity?: string,
        public returnState?: string,
        public carType?: string,
        public fleetId?: string,
        public origEdaEtaCustomer?: Date,
        public origEdaEtaOrigin?: Date,
        public badOrderDays?: number,
        public carCondition?: string,
        public carStatus?: string,
        public tripStatus?: string,
        public sightCode?: string,
        public clmDateTime?: Date,
        public locationCity?: string,
        public locationState?: string,
        public loadEmpty?: string,
        public road?: string,
        public destinationArrDateTime?: Date,
        public destinationArrType?: string,
        public customerDeliveryDateTime?: Date,
        public customerDeliveryType?: string,
        public customerReleaseDateTime?: Date,
        public customerReleaseType?: string,
        public tripCloseDateTime?: Date,
        public tripCloseType?: string,
        public businessGroup?: string,
        public divertYorN?: string,
        public shopMove?: string,
        public oneWayRound?: string,
        public procDateTime?: Date,
        public freeRunner?: string,
        public supplierShipment?: string,
        public previousTripCloseDateTime?: Date,
        public freightPmtCode?: string,
        public comments?: string,
        public shipmentType?: string,
        public bolNo?: string,
        public shipperNo?: string,
        public offRouteStatus?: number,
        public offRouteCity?: string,
        public offRouteState?: string,
        public custPoNo?: string,
        public requestedDeliveryDateTime?: Date,
        public extractYorN1?: string,
        public originalEtaToCustomer?: Date,
        public originalEtaToOrigin?: Date,
        public etaManualClose?: string,
        public etaProcDate?: Date,
        public supplierCode?: string,
        public supplierName?: string,
        public csrNo?: string,
        public unitTrainId?: string,
        public billDetentionYorN?: string,
        public detentionAmount?: number,
        public totalTimeAtDestination?: number,
        public chargeTimeAtDest?: number,
        public freeTime?: number,
        public extractDate?: Date,
        public detentionExtractYorN?: string,
        public detentionUom?: string,
        public hotListFlag?: string,
        public hotListFlagMechanical?: string,
        public hotListFlagLease?: string,
        public salesRep?: string,
        public masterTripNo?: string,
        public masterOrigin?: string,
        public troubleYorN?: string,
        public gpsAlertFlag?: string,
        public importSource?: string,
        public railRoadDeliveryDateTime?: Date,
        public railRoadUpdateDateTime?: Date,
        public railRoadDeliveryUpdateRoad?: string,
        public ormId?: number,
        public userId?: string,
        public updateDateTime?: Date,
        public tcmEquipmentOrmId?: number,
        public trip_ref1?: string,
        public trip_ref2?: string,
        public trip_ref3?: string,
        public trip_ref4?: string,
        public trip_ref5?: string,
        public trip_ref6?: string,
        public trip_ref7?: string,
        public trip_ref8?: string,
        public trip_ref9?: string,
        public trip_ref10?: string,
        public trip_ref11?: string,
        public trip_ref12?: string,
        public trip_ref13?: string,
        public trip_ref14?: string,
        public trip_ref15?: string,
        public trip_ref16?: string,
        public trip_ref17?: string,
        public trip_ref18?: string,
        public trip_ref19?: string,
        public trip_ref20?: string,
        public isReturnCityEditable?: boolean,

        public commoditiesShipped?: Array<RtCommodityShipped>,
    ) {}
}

export const relationship: IModelMetadata[] = [
    { client: nameof<RtTrip>('carInit'), server: 'carInit' },
    { client: nameof<RtTrip>('carNo'), server: 'carNo' },
    { client: nameof<RtTrip>('shipDatetime'), server: 'shipDatetime' },
    { client: nameof<RtTrip>('customerNo'), server: 'customerNo' },
    { client: nameof<RtTrip>('routeCode'), server: 'routeCode' },
    { client: nameof<RtTrip>('tripNo'), server: 'tripNo' },
    { client: nameof<RtTrip>('originCity'), server: 'originCity' },
    { client: nameof<RtTrip>('originState'), server: 'originState' },
    { client: nameof<RtTrip>('destinationCity'), server: 'destinationCity' },
    { client: nameof<RtTrip>('destinationState'), server: 'destinationState' },
    { client: nameof<RtTrip>('releaseCity'), server: 'releaseCity' },
    { client: nameof<RtTrip>('releaseState'), server: 'releaseState' },
    { client: nameof<RtTrip>('returnCity'), server: 'returnCity' },
    { client: nameof<RtTrip>('returnState'), server: 'returnState' },
    { client: nameof<RtTrip>('carType'), server: 'carType' },
    { client: nameof<RtTrip>('fleetId'), server: 'fleetId' },
    { client: nameof<RtTrip>('origEdaEtaCustomer'), server: 'origEdaEtaCustomer' },
    { client: nameof<RtTrip>('origEdaEtaOrigin'), server: 'origEdaEtaOrigin' },
    { client: nameof<RtTrip>('badOrderDays'), server: 'badOrderDays' },
    { client: nameof<RtTrip>('carCondition'), server: 'carCondition' },
    { client: nameof<RtTrip>('carStatus'), server: 'carStatus' },
    { client: nameof<RtTrip>('tripStatus'), server: 'tripStatus' },
    { client: nameof<RtTrip>('sightCode'), server: 'sightCode' },
    { client: nameof<RtTrip>('clmDateTime'), server: 'clmDateTime' },
    { client: nameof<RtTrip>('locationCity'), server: 'locationCity' },
    { client: nameof<RtTrip>('locationState'), server: 'locationState' },
    { client: nameof<RtTrip>('loadEmpty'), server: 'loadEmpty' },
    { client: nameof<RtTrip>('road'), server: 'road' },
    { client: nameof<RtTrip>('destinationArrDateTime'), server: 'destinationArrDateTime' },
    { client: nameof<RtTrip>('destinationArrType'), server: 'destinationArrType' },
    { client: nameof<RtTrip>('customerDeliveryDateTime'), server: 'customerDeliveryDateTime' },
    { client: nameof<RtTrip>('customerDeliveryType'), server: 'customerDeliveryType' },
    { client: nameof<RtTrip>('customerReleaseDateTime'), server: 'customerReleaseDateTime' },
    { client: nameof<RtTrip>('customerReleaseType'), server: 'customerReleaseType' },
    { client: nameof<RtTrip>('tripCloseDateTime'), server: 'tripCloseDateTime' },
    { client: nameof<RtTrip>('tripCloseType'), server: 'tripCloseType' },
    { client: nameof<RtTrip>('businessGroup'), server: 'businessGroup' },
    { client: nameof<RtTrip>('divertYorN'), server: 'divertYorN' },
    { client: nameof<RtTrip>('shopMove'), server: 'shopMove' },
    { client: nameof<RtTrip>('oneWayRound'), server: 'oneWayRound' },
    { client: nameof<RtTrip>('procDateTime'), server: 'procDateTime' },
    { client: nameof<RtTrip>('freeRunner'), server: 'freeRunner' },
    { client: nameof<RtTrip>('supplierShipment'), server: 'supplierShipment' },
    { client: nameof<RtTrip>('previousTripCloseDateTime'), server: 'previousTripCloseDateTime' },
    { client: nameof<RtTrip>('freightPmtCode'), server: 'freightPmtCode' },
    { client: nameof<RtTrip>('comments'), server: 'comments' },
    { client: nameof<RtTrip>('shipmentType'), server: 'shipmentType' },
    { client: nameof<RtTrip>('bolNo'), server: 'bolNo' },
    { client: nameof<RtTrip>('shipperNo'), server: 'shipperNo' },
    { client: nameof<RtTrip>('offRouteStatus'), server: 'offRouteStatus' },
    { client: nameof<RtTrip>('offRouteCity'), server: 'offRouteCity' },
    { client: nameof<RtTrip>('offRouteState'), server: 'offRouteState' },
    { client: nameof<RtTrip>('custPoNo'), server: 'custPoNo' },
    { client: nameof<RtTrip>('requestedDeliveryDateTime'), server: 'requestedDeliveryDateTime' },
    { client: nameof<RtTrip>('extractYorN1'), server: 'extractYorN1' },
    { client: nameof<RtTrip>('originalEtaToCustomer'), server: 'originalEtaToCustomer' },
    { client: nameof<RtTrip>('originalEtaToOrigin'), server: 'originalEtaToOrigin' },
    { client: nameof<RtTrip>('etaManualClose'), server: 'etaManualClose' },
    { client: nameof<RtTrip>('etaProcDate'), server: 'etaProcDate' },
    { client: nameof<RtTrip>('supplierCode'), server: 'supplierCode' },
    { client: nameof<RtTrip>('supplierName'), server: 'supplierName' },
    { client: nameof<RtTrip>('csrNo'), server: 'csrNo' },
    { client: nameof<RtTrip>('unitTrainId'), server: 'unitTrainId' },
    { client: nameof<RtTrip>('billDetentionYorN'), server: 'billDetentionYorN' },
    { client: nameof<RtTrip>('detentionAmount'), server: 'detentionAmount' },
    { client: nameof<RtTrip>('totalTimeAtDestination'), server: 'totalTimeAtDestination' },
    { client: nameof<RtTrip>('chargeTimeAtDest'), server: 'chargeTimeAtDest' },
    { client: nameof<RtTrip>('freeTime'), server: 'freeTime' },
    { client: nameof<RtTrip>('extractDate'), server: 'extractDate' },
    { client: nameof<RtTrip>('detentionExtractYorN'), server: 'detentionExtractYorN' },
    { client: nameof<RtTrip>('detentionUom'), server: 'detentionUom' },
    { client: nameof<RtTrip>('hotListFlag'), server: 'hotListFlag' },
    { client: nameof<RtTrip>('hotListFlagMechanical'), server: 'hotListFlagMechanical' },
    { client: nameof<RtTrip>('hotListFlagLease'), server: 'hotListFlagLease' },
    { client: nameof<RtTrip>('salesRep'), server: 'salesRep' },
    { client: nameof<RtTrip>('masterTripNo'), server: 'masterTripNo' },
    { client: nameof<RtTrip>('masterOrigin'), server: 'masterOrigin' },
    { client: nameof<RtTrip>('troubleYorN'), server: 'troubleYorN' },
    { client: nameof<RtTrip>('gpsAlertFlag'), server: 'gpsAlertFlag' },
    { client: nameof<RtTrip>('importSource'), server: 'importSource' },
    { client: nameof<RtTrip>('railRoadDeliveryDateTime'), server: 'railRoadDeliveryDateTime' },
    { client: nameof<RtTrip>('railRoadUpdateDateTime'), server: 'railRoadUpdateDateTime' },
    { client: nameof<RtTrip>('railRoadDeliveryUpdateRoad'), server: 'railRoadDeliveryUpdateRoad' },
    { client: nameof<RtTrip>('ormId'), server: 'ormId' },
    { client: nameof<RtTrip>('userId'), server: 'userId' },
    { client: nameof<RtTrip>('updateDateTime'), server: 'updateDateTime' },
    { client: nameof<RtTrip>('tcmEquipmentOrmId'), server: 'tcmEquipmentOrmId' },
    { client: nameof<RtTrip>('trip_ref1'), server: 'trip_ref1' },
    { client: nameof<RtTrip>('trip_ref2'), server: 'trip_ref2' },
    { client: nameof<RtTrip>('trip_ref3'), server: 'trip_ref3' },
    { client: nameof<RtTrip>('trip_ref4'), server: 'trip_ref4' },
    { client: nameof<RtTrip>('trip_ref5'), server: 'trip_ref5' },
    { client: nameof<RtTrip>('trip_ref6'), server: 'trip_ref6' },
    { client: nameof<RtTrip>('trip_ref7'), server: 'trip_ref7' },
    { client: nameof<RtTrip>('trip_ref8'), server: 'trip_ref8' },
    { client: nameof<RtTrip>('trip_ref9'), server: 'trip_ref9' },
    { client: nameof<RtTrip>('trip_ref10'), server: 'trip_ref10' },
    { client: nameof<RtTrip>('trip_ref11'), server: 'trip_ref11' },
    { client: nameof<RtTrip>('trip_ref12'), server: 'trip_ref12' },
    { client: nameof<RtTrip>('trip_ref13'), server: 'trip_ref13' },
    { client: nameof<RtTrip>('trip_ref14'), server: 'trip_ref14' },
    { client: nameof<RtTrip>('trip_ref15'), server: 'trip_ref15' },
    { client: nameof<RtTrip>('trip_ref16'), server: 'trip_ref16' },
    { client: nameof<RtTrip>('trip_ref17'), server: 'trip_ref17' },
    { client: nameof<RtTrip>('trip_ref18'), server: 'trip_ref18' },
    { client: nameof<RtTrip>('trip_ref19'), server: 'trip_ref19' },
    { client: nameof<RtTrip>('trip_ref20'), server: 'trip_ref20' },

    {
        client: nameof<RtTrip>('commoditiesShipped'),
        server: 'commoditiesShipped',
        adapter: new RtCommodityShippedAdapter(),
    },
];

@Injectable({
    providedIn: 'root',
})
export class RtTripAdapter extends TwoWayAdapter<RtTrip> {
    constructor() {
        super();
    }
    public metadata: IModelMetadata[] = relationship;
}

// https://www.devexpress.com/Support/Center/Question/Details/T746351/datagrid-how-to-set-columns-at-runtime
@Injectable({
    providedIn: 'root',
})
export class RtTripMetadata implements GridColumnDescription, FormGroupDescription {
    constructor() {}
    getColumns(defaultVisibility: boolean = true): IColumnDescription[] {
        const columns = [
            {
                dataField: nameof<RtTrip>('carInit'),
                dataType: 'string',
                caption: 'Car Init',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('carNo'),
                dataType: 'string',
                caption: 'Car Number',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('shipDatetime'),
                dataType: 'datetime',
                caption: 'Shipment Date',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: 'customerNo',
                dataType: 'string',
                caption: 'Cust Number',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('routeCode'),
                dataType: 'string',
                caption: 'routecode',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('tripNo'),
                dataType: 'string',
                caption: 'tripno',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('originCity'),
                dataType: 'string',
                caption: 'origincity',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('originState'),
                dataType: 'string',
                caption: 'originstate',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('destinationCity'),
                dataType: 'string',
                caption: 'destinationcity',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('destinationState'),
                dataType: 'string',
                caption: 'destinationstate',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('releaseCity'),
                dataType: 'string',
                caption: 'releasecity',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('releaseState'),
                dataType: 'string',
                caption: 'releasestate',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('returnCity'),
                dataType: 'string',
                caption: 'returncity',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('returnState'),
                dataType: 'string',
                caption: 'returnstate',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('carType'),
                dataType: 'string',
                caption: 'cartype',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('fleetId'),
                dataType: 'string',
                caption: 'fleetid',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('origEdaEtaCustomer'),
                dataType: 'datetime',
                caption: 'origedaetacustomer',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('origEdaEtaOrigin'),
                dataType: 'datetime',
                caption: 'origedaetaorigin',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('badOrderDays'),
                dataType: 'number',
                caption: 'badorderdays',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('carCondition'),
                dataType: 'string',
                caption: 'carcondition',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('carStatus'),
                dataType: 'string',
                caption: 'carstatus',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('tripStatus'),
                dataType: 'string',
                caption: 'tripstatus',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('sightCode'),
                dataType: 'string',
                caption: 'sightcode',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('clmDateTime'),
                dataType: 'datetime',
                caption: 'clmdatetime',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('locationCity'),
                dataType: 'string',
                caption: 'locationcity',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('locationState'),
                dataType: 'string',
                caption: 'locationstate',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('loadEmpty'),
                dataType: 'string',
                caption: 'loadempty',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('road'),
                dataType: 'string',
                caption: 'road',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('destinationArrDateTime'),
                dataType: 'datetime',
                caption: 'destinationarrdatetime',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('destinationArrType'),
                dataType: 'string',
                caption: 'destinationarrtype',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('customerDeliveryDateTime'),
                dataType: 'datetime',
                caption: 'customerdeliverydatetime',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('customerDeliveryType'),
                dataType: 'string',
                caption: 'customerdeliverytype',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('customerReleaseDateTime'),
                dataType: 'datetime',
                caption: 'customerreleasedatetime',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('customerReleaseType'),
                dataType: 'string',
                caption: 'customerreleasetype',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('tripCloseDateTime'),
                dataType: 'datetime',
                caption: 'tripclosedatetime',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('tripCloseType'),
                dataType: 'string',
                caption: 'tripclosetype',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('businessGroup'),
                dataType: 'string',
                caption: 'businessgroup',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('divertYorN'),
                dataType: 'string',
                caption: 'divertyorn',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('shopMove'),
                dataType: 'string',
                caption: 'shopmove',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('oneWayRound'),
                dataType: 'string',
                caption: 'onewayround',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('procDateTime'),
                dataType: 'datetime',
                caption: 'procdatetime',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('freeRunner'),
                dataType: 'string',
                caption: 'freerunner',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('supplierShipment'),
                dataType: 'string',
                caption: 'suppliershipment',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('previousTripCloseDateTime'),
                dataType: 'datetime',
                caption: 'previoustripclosedatetime',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('freightPmtCode'),
                dataType: 'string',
                caption: 'freightpmtcode',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('comments'),
                dataType: 'string',
                caption: 'comments',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('shipmentType'),
                dataType: 'string',
                caption: 'shipmenttype',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('bolNo'),
                dataType: 'string',
                caption: 'bolno',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('shipperNo'),
                dataType: 'string',
                caption: 'shipperno',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('offRouteStatus'),
                dataType: 'number',
                caption: 'offroutestatus',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('offRouteCity'),
                dataType: 'string',
                caption: 'offroutecity',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('offRouteState'),
                dataType: 'string',
                caption: 'offroutestate',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('custPoNo'),
                dataType: 'string',
                caption: 'custpono',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('requestedDeliveryDateTime'),
                dataType: 'datetime',
                caption: 'requesteddeliverydatetime',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('extractYorN1'),
                dataType: 'string',
                caption: 'extractyorn1',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('originalEtaToCustomer'),
                dataType: 'datetime',
                caption: 'originaletatocustomer',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('originalEtaToOrigin'),
                dataType: 'datetime',
                caption: 'originaletatoorigin',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('etaManualClose'),
                dataType: 'string',
                caption: 'etamanualclose',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('etaProcDate'),
                dataType: 'datetime',
                caption: 'etaprocdate',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('supplierCode'),
                dataType: 'string',
                caption: 'suppliercode',
                visible: defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('supplierName'),
                dataType: 'string',
                caption: 'suppliername',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('csrNo'),
                dataType: 'string',
                caption: 'csrno',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('unitTrainId'),
                dataType: 'string',
                caption: 'unittrainid',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('billDetentionYorN'),
                dataType: 'string',
                caption: 'billdetentionyorn',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('detentionAmount'),
                dataType: 'number',
                caption: 'detentionamount',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('totalTimeAtDestination'),
                dataType: 'number',
                caption: 'totaltimeatdestination',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('chargeTimeAtDest'),
                dataType: 'number',
                caption: 'chargetimeatdest',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('freeTime'),
                dataType: 'number',
                caption: 'freetime',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('extractDate'),
                dataType: 'datetime',
                caption: 'extractdate',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('detentionExtractYorN'),
                dataType: 'string',
                caption: 'detentionextractyorn',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('detentionUom'),
                dataType: 'string',
                caption: 'detentionuom',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('hotListFlag'),
                dataType: 'string',
                caption: 'hotlistflag',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('hotListFlagMechanical'),
                dataType: 'string',
                caption: 'hotlistflagmechanical',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('hotListFlagLease'),
                dataType: 'string',
                caption: 'hotlistflaglease',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('salesRep'),
                dataType: 'string',
                caption: 'salesrep',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('masterTripNo'),
                dataType: 'string',
                caption: 'mastertripno',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('masterOrigin'),
                dataType: 'string',
                caption: 'masterorigin',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('troubleYorN'),
                dataType: 'string',
                caption: 'troubleyorn',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('gpsAlertFlag'),
                dataType: 'string',
                caption: 'gpsalertflag',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('importSource'),
                dataType: 'string',
                caption: 'importsource',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('railRoadDeliveryDateTime'),
                dataType: 'datetime',
                caption: 'railroaddeliverydatetime',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('railRoadUpdateDateTime'),
                dataType: 'datetime',
                caption: 'railroadupdatedatetime',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('railRoadDeliveryUpdateRoad'),
                dataType: 'string',
                caption: 'railroaddeliveryupdateroad',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('ormId'),
                dataType: 'number',
                caption: 'ormid',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('userId'),
                dataType: 'string',
                caption: 'userid',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('updateDateTime'),
                dataType: 'datetime',
                caption: 'updatedatetime',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('tcmEquipmentOrmId'),
                dataType: 'number',
                caption: 'tcmEquipmentOrmId',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('trip_ref1'),
                dataType: 'string',
                caption: 'trip_ref1',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('trip_ref2'),
                dataType: 'string',
                caption: 'trip_ref2',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('trip_ref3'),
                dataType: 'string',
                caption: 'trip_ref3',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('trip_ref4'),
                dataType: 'string',
                caption: 'trip_ref4',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('trip_ref5'),
                dataType: 'string',
                caption: 'trip_ref5',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('trip_ref6'),
                dataType: 'string',
                caption: 'trip_ref6',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('trip_ref7'),
                dataType: 'string',
                caption: 'trip_ref7',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('trip_ref8'),
                dataType: 'string',
                caption: 'trip_ref8',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('trip_ref9'),
                dataType: 'string',
                caption: 'trip_ref9',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('trip_ref10'),
                dataType: 'string',
                caption: 'trip_ref10',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('trip_ref11'),
                dataType: 'string',
                caption: 'trip_ref11',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('trip_ref12'),
                dataType: 'string',
                caption: 'trip_ref12',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('trip_ref13'),
                dataType: 'string',
                caption: 'trip_ref13',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('trip_ref14'),
                dataType: 'string',
                caption: 'trip_ref14',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('trip_ref15'),
                dataType: 'string',
                caption: 'trip_ref15',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('trip_ref16'),
                dataType: 'string',
                caption: 'trip_ref16',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('trip_ref17'),
                dataType: 'string',
                caption: 'trip_ref17',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('trip_ref18'),
                dataType: 'string',
                caption: 'trip_ref18',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('trip_ref19'),
                dataType: 'string',
                caption: 'trip_ref19',
                visible: !defaultVisibility,
            } as IColumnDescription,
            {
                dataField: nameof<RtTrip>('trip_ref20'),
                dataType: 'string',
                caption: 'trip_ref20',
                visible: !defaultVisibility,
            } as IColumnDescription,
        ];
        return columns;
    }

    getFormGroup(obj?: any): UntypedFormGroup {
        const formGroup = new UntypedFormGroup({});
        formGroup.addControl(nameof<RtTrip>('carInit'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTrip>('carNo'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTrip>('shipDatetime'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTrip>('customerNo'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTrip>('routeCode'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTrip>('tripNo'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTrip>('originCity'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTrip>('originState'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTrip>('destinationCity'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTrip>('destinationState'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTrip>('releaseCity'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTrip>('releaseState'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTrip>('returnCity'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTrip>('returnState'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTrip>('carType'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTrip>('fleetId'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTrip>('origEdaEtaCustomer'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTrip>('origEdaEtaOrigin'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTrip>('badOrderDays'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTrip>('carCondition'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTrip>('carStatus'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTrip>('tripStatus'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTrip>('sightCode'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTrip>('clmDateTime'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTrip>('locationCity'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTrip>('locationState'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTrip>('loadEmpty'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTrip>('road'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTrip>('destinationArrDateTime'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTrip>('destinationArrType'), new UntypedFormControl(''));
        formGroup.addControl(
            nameof<RtTrip>('customerDeliveryDateTime'),
            new UntypedFormControl(''),
        );
        formGroup.addControl(nameof<RtTrip>('customerDeliveryType'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTrip>('customerReleaseDateTime'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTrip>('customerReleaseType'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTrip>('tripCloseDateTime'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTrip>('tripCloseType'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTrip>('businessGroup'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTrip>('divertYorN'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTrip>('shopMove'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTrip>('oneWayRound'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTrip>('procDateTime'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTrip>('freeRunner'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTrip>('supplierShipment'), new UntypedFormControl(''));
        formGroup.addControl(
            nameof<RtTrip>('previousTripCloseDateTime'),
            new UntypedFormControl(''),
        );
        formGroup.addControl(nameof<RtTrip>('freightPmtCode'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTrip>('comments'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTrip>('shipmentType'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTrip>('bolNo'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTrip>('shipperNo'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTrip>('offRouteStatus'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTrip>('offRouteCity'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTrip>('offRouteState'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTrip>('custPoNo'), new UntypedFormControl(''));
        formGroup.addControl(
            nameof<RtTrip>('requestedDeliveryDateTime'),
            new UntypedFormControl(''),
        );
        formGroup.addControl(nameof<RtTrip>('extractYorN1'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTrip>('originalEtaToCustomer'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTrip>('originalEtaToOrigin'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTrip>('etaManualClose'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTrip>('etaProcDate'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTrip>('supplierCode'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTrip>('supplierName'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTrip>('csrNo'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTrip>('unitTrainId'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTrip>('billDetentionYorN'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTrip>('detentionAmount'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTrip>('totalTimeAtDestination'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTrip>('chargeTimeAtDest'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTrip>('freeTime'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTrip>('extractDate'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTrip>('detentionExtractYorN'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTrip>('detentionUom'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTrip>('hotListFlag'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTrip>('trip_ref1'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTrip>('trip_ref2'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTrip>('trip_ref3'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTrip>('trip_ref4'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTrip>('hotListFlagMechanical'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTrip>('hotListFlagLease'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTrip>('salesRep'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTrip>('masterTripNo'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTrip>('masterOrigin'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTrip>('troubleYorN'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTrip>('trip_ref5'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTrip>('trip_ref6'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTrip>('gpsAlertFlag'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTrip>('importSource'), new UntypedFormControl(''));
        formGroup.addControl(
            nameof<RtTrip>('railRoadDeliveryDateTime'),
            new UntypedFormControl(''),
        );
        formGroup.addControl(nameof<RtTrip>('railRoadUpdateDateTime'), new UntypedFormControl(''));
        formGroup.addControl(
            nameof<RtTrip>('railRoadDeliveryUpdateRoad'),
            new UntypedFormControl(''),
        );
        formGroup.addControl(nameof<RtTrip>('ormId'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTrip>('userId'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTrip>('updateDateTime'), new UntypedFormControl(''));
        formGroup.addControl(nameof<RtTrip>('tcmEquipmentOrmId'), new UntypedFormControl(''));

        if (!!obj) {
            formGroup.setValue(obj);
        }

        this.setValidators(formGroup);
        return formGroup;
    }

    setValidators(form: UntypedFormGroup, isLoadAndHold?: boolean, ormId?: number): void {
        const requiredValidator = (isLoadAndHold && ormId) || ormId ? [Validators.required] : [];
        const standardValidators = isLoadAndHold ? [] : [Validators.required];

        form.controls[nameof<RtTrip>('carInit')].setValidators([
            Validators.required,
            Validators.maxLength(4),
        ]);
        form.controls[nameof<RtTrip>('carNo')].setValidators([
            Validators.required,
            Validators.maxLength(6),
        ]);
        form.controls[nameof<RtTrip>('bolNo')].setValidators([Validators.maxLength(80)]);
        form.controls[nameof<RtTrip>('supplierName')].setValidators([Validators.maxLength(30)]);
        form.controls[nameof<RtTrip>('unitTrainId')].setValidators([Validators.maxLength(15)]);
        form.controls[nameof<RtTrip>('shipperNo')].setValidators([Validators.maxLength(13)]);
        form.controls[nameof<RtTrip>('trip_ref1')].setValidators([Validators.maxLength(20)]);
        form.controls[nameof<RtTrip>('trip_ref2')].setValidators([Validators.maxLength(20)]);
        form.controls[nameof<RtTrip>('trip_ref3')].setValidators([Validators.maxLength(20)]);
        form.controls[nameof<RtTrip>('trip_ref4')].setValidators([Validators.maxLength(20)]);
        form.controls[nameof<RtTrip>('trip_ref5')].setValidators([Validators.maxLength(25)]);
        form.controls[nameof<RtTrip>('trip_ref6')].setValidators([Validators.maxLength(25)]);
        form.controls[nameof<RtTrip>('shipDatetime')].setValidators([Validators.required]);
        form.controls[nameof<RtTrip>('originCity')].setValidators(
            isLoadAndHold
                ? [Validators.required, Validators.maxLength(9)]
                : [Validators.maxLength(9)],
        );
        form.controls[nameof<RtTrip>('originState')].setValidators(
            isLoadAndHold ? [Validators.required] : [],
        );
        form.controls[nameof<RtTrip>('routeCode')].setValidators([
            ...standardValidators,
            Validators.maxLength(20),
        ]);
        form.controls[nameof<RtTrip>('customerNo')].setValidators([
            ...standardValidators,
            Validators.maxLength(13),
        ]);
        form.controls[nameof<RtTrip>('destinationArrDateTime')].setValidators(requiredValidator);
        form.controls[nameof<RtTrip>('customerDeliveryDateTime')].setValidators(requiredValidator);
        form.controls[nameof<RtTrip>('customerReleaseDateTime')].setValidators(requiredValidator);
        form.controls[nameof<RtTrip>('tripCloseDateTime')].setValidators(requiredValidator);
        form.controls[nameof<RtTrip>('destinationArrType')].setValidators(requiredValidator);
        form.controls[nameof<RtTrip>('customerDeliveryType')].setValidators(requiredValidator);
        form.controls[nameof<RtTrip>('customerReleaseType')].setValidators(requiredValidator);
        form.controls[nameof<RtTrip>('tripCloseType')].setValidators(requiredValidator);

        form.controls[nameof<RtTrip>('carInit')].updateValueAndValidity();
        form.controls[nameof<RtTrip>('carNo')].updateValueAndValidity();
        form.controls[nameof<RtTrip>('shipDatetime')].updateValueAndValidity();
        form.controls[nameof<RtTrip>('originCity')].updateValueAndValidity();
        form.controls[nameof<RtTrip>('originState')].updateValueAndValidity();
        form.controls[nameof<RtTrip>('routeCode')].updateValueAndValidity();
        form.controls[nameof<RtTrip>('customerNo')].updateValueAndValidity();
        form.controls[nameof<RtTrip>('destinationArrDateTime')].updateValueAndValidity();
        form.controls[nameof<RtTrip>('customerDeliveryDateTime')].updateValueAndValidity();
        form.controls[nameof<RtTrip>('customerReleaseDateTime')].updateValueAndValidity();
        form.controls[nameof<RtTrip>('tripCloseDateTime')].updateValueAndValidity();
        form.controls[nameof<RtTrip>('destinationArrType')].updateValueAndValidity();
        form.controls[nameof<RtTrip>('customerDeliveryType')].updateValueAndValidity();
        form.controls[nameof<RtTrip>('customerReleaseType')].updateValueAndValidity();
        form.controls[nameof<RtTrip>('tripCloseType')].updateValueAndValidity();

        form.controls[nameof<RtTrip>('bolNo')].updateValueAndValidity();
        form.controls[nameof<RtTrip>('supplierName')].updateValueAndValidity();
        form.controls[nameof<RtTrip>('unitTrainId')].updateValueAndValidity();
        form.controls[nameof<RtTrip>('shipperNo')].updateValueAndValidity();
        form.controls[nameof<RtTrip>('trip_ref1')].updateValueAndValidity();
        form.controls[nameof<RtTrip>('trip_ref2')].updateValueAndValidity();
        form.controls[nameof<RtTrip>('trip_ref3')].updateValueAndValidity();
        form.controls[nameof<RtTrip>('trip_ref4')].updateValueAndValidity();
        form.controls[nameof<RtTrip>('trip_ref5')].updateValueAndValidity();
        form.controls[nameof<RtTrip>('trip_ref6')].updateValueAndValidity();
    }
}
