import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatDrawer } from '@angular/material/sidenav';
import { ActivatedRoute, Router } from '@angular/router';
import { RtRouteService } from '@bds/data-access';
import { faSave } from '@bds/fa-svg-icons';
import { RtRouteCode } from '@bds/railtrac-models';
import { BdsSysDefConfig, BdsSysDefService } from '@bds/sysdef';
import { faBars, faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { firstValueFrom, of, Subject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { BdsPathCodeFormComponent } from '../../components/bds-path-code-form/bds-path-code-form.component';
import { RouteCodeFormService } from '../../components/bds-route-code/bds-route-code-form.service';
import { BdsRouteCodeComponent } from '../../components/bds-route-code/bds-route-code.component';
import { BdsRouteSegmentBuilderComponent } from '../../components/bds-route-segment-builder/bds-route-segment-builder.component';
import {
    BdsRoutePathCodeService,
    RtRoutePathCode,
} from '../../services/bds-route-path-code.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'bds-route-create-screen',
    templateUrl: './route-create-screen.component.html',
    providers: [RouteCodeFormService],
    styleUrls: ['./route-create-screen.component.scss'],
})
export class RouteCreateScreenComponent implements OnInit, OnDestroy {
    @ViewChild(BdsRouteCodeComponent) bdsRouteCode: BdsRouteCodeComponent;
    @ViewChild(BdsRouteSegmentBuilderComponent)
    bdsRouteSegmentBuilderComponent: BdsRouteSegmentBuilderComponent;
    @ViewChild(MatDrawer) drawer: MatDrawer;
    errors: [
        { validationField: string; validationMessage: string; validationMessageTypeId: number },
    ];
    iconMenu = faBars;
    iconSave = faSave;
    iconError = faExclamationTriangle;
    showFiller = false;
    isSaveBtnDisabled = false;
    private config: BdsSysDefConfig;
    private unsub$ = new Subject<void>();

    constructor(
        private routeService: RtRouteService,
        private routeCodeFormService: RouteCodeFormService,
        private router: Router,
        private route: ActivatedRoute,
        private pathCodeService: BdsRoutePathCodeService,
        private sysDef: BdsSysDefService,
        private dialog: MatDialog,
        private snackBar: MatSnackBar,
    ) {}

    getPathCode(): Observable<any> {
        const origin = <string>this.routeCodeFormService.originSwitch.value;
        const destination = <string>this.routeCodeFormService.deliverySwitch.value;
        const routeDscr = <string>this.routeCodeFormService.routeDscr.value;

        if (
            this.config &&
            this.config.routeOverride !== 'Y' &&
            this.config.pathCodeSearchEnabled !== true
        ) {
            return this.savePathCode(routeDscr, origin, destination);
        } else {
            return this.pathCodeService.getByRouteDscr(routeDscr, origin, destination).pipe(
                switchMap((response) => {
                    if (response?.pathCode) {
                        return of(response.pathCode);
                    }
                    return this.savePathCode(routeDscr, origin, destination);
                }),
                tap((pathCode) => {
                    if (pathCode && pathCode !== '') {
                        this.routeCodeFormService.pathCode.setValue(pathCode);
                    }
                }),
            );
        }
    }

    savePathCode(routeDscr: string, origin: string, destination: string) {
        const pathCode = <RtRoutePathCode>{
            pathDescription: routeDscr,
            originRoad: origin,
            deliveryRoad: destination,
        };

        return this.pathCodeService
            .create(pathCode)
            .pipe(
                map(
                    (response: { relatedObject: RtRoutePathCode }) =>
                        response.relatedObject.pathCode,
                ),
            );
    }

    ngOnDestroy(): void {
        this.unsub$.next();
        this.unsub$.complete();
    }

    ngOnInit(): void {
        this.initConfig();

        this.routeCodeFormService.form.valueChanges.subscribe(() => {
            let request: RtRouteCode = this.routeCodeFormService.form.getRawValue();
            this.isSaveBtnDisabled = !(
                this.routeCodeFormService.form.valid &&
                request['segments']?.some((x) => x.railroad != null)
            );
        });
    }

    openPathCodeDialog(
        routeDscr: string,
        origin: string,
        destination: string,
    ): Observable<any | undefined> {
        return this.dialog
            .open(BdsPathCodeFormComponent, {
                minWidth: '700px',
                data: {
                    pathDescription: routeDscr,
                    pathCode: null,
                    originRoad: origin,
                    deliveryRoad: destination,
                    routeOverride: this.config?.routeOverride,
                    pathCodeSearchEnabled: this.config?.pathCodeSearchEnabled,
                },
            })
            .afterClosed();
    }

    save() {
        if (this.routeCodeFormService.form.valid) {
            let request: RtRouteCode = this.routeCodeFormService.form.getRawValue();
            request.lastClmPerfCalc = null;
            request.oneWayRound = 'R';
            this.getPathCode()
                .pipe(
                    filter((response) => response != ''),
                    switchMap(async (pathCode) => {
                        request.pathCode = pathCode;
                        return await this.createRoute(request);
                    }),
                    tap(
                        (response: {
                            bourqueDataResult: {
                                succeeded: boolean;
                                validationErrors: [
                                    {
                                        validationField: string;
                                        validationMessage: string;
                                        validationMessageTypeId: number;
                                    },
                                ];
                            };
                            relatedObject: RtRouteCode;
                        }) => {
                            if (!response.bourqueDataResult.succeeded) {
                                this.errors = response.bourqueDataResult.validationErrors;
                                this.snackBar.open('Route Save failed!', 'Error', {
                                    duration: 3000,
                                });
                                void this.drawer.toggle();
                            } else {
                                void this.router.navigate(
                                    ['../detail', response['relatedObject'].routeCode],
                                    {
                                        relativeTo: this.route,
                                    },
                                );
                            }
                        },
                    ),
                )
                .subscribe();
        }
    }

    private async createRoute(request: RtRouteCode) {
        try {
            const result = await firstValueFrom(this.routeService.create(request));
            this.snackBar.open('Route Saved!', 'Ok', { duration: 3000 });
            return result;
        } catch (error) {
            this.snackBar.open('Route Save failed! ' + error, 'Error', { duration: 3000 });
        }
    }

    private initConfig() {
        this.sysDef
            .getConfig()
            .pipe(
                takeUntil(this.unsub$),
                tap((response) => {
                    this.config = response;
                }),
            )
            .subscribe();
    }
}
