import { Injectable } from '@angular/core';
import { relationship, RtTripDiversion } from '@bds/railtrac-models';
import { RtDiversionDetail } from '../models/rt-diversion-detail.model';
import { RtTripsByRoad } from '../models/rt-trips-by-road.model';
import { RtDiversionFormModel } from '../models/rt-diversion-form.model';
import { IModelMetadata, TwoWayAdapter } from '@bds/core';

@Injectable({
    providedIn: 'root',
})
export class RtDiversionAdapterService {
    constructor() {}

    filterRequestedDiversions(roads: RtTripsByRoad[]): RtTripsByRoad[] {
        let filteredRoads: RtTripsByRoad[] = [];

        if (roads && roads.length) {
            roads.forEach((r) => {
                r.diversions = r.diversions.filter((d) => d.divert);
                if (r.diversions && r.diversions.length) {
                    filteredRoads = filteredRoads.concat(r);
                }
            });
        }

        return filteredRoads;
    }

    flattenDiversions(roads: RtTripsByRoad[]): RtDiversionDetail[] {
        let diversions: RtDiversionDetail[] = [];

        if (roads && roads.length) {
            roads.forEach((r) => {
                diversions = diversions.concat(r.diversions.filter((t) => t.divert));
            });

            return diversions;
        }

        return [];
    }

    groupByRoad(diversions: RtTripDiversion[]): RtTripsByRoad[] {
        if (diversions && diversions.length) {
            const groupedData: RtTripsByRoad[] =
                diversions.reduce(function (rv, x) {
                    // Convert RtTripDiversion to RtDiversionDetail
                    const d: RtDiversionDetail = {
                        bolNo: null,
                        businessGroup: x.businessGroup,
                        carInit: x.carInit,
                        carNo: x.carNo,
                        carStatus: x.carStatus,
                        clmDateTime: x.clmDateTime,
                        clmLe: x.clmLe,
                        clmLocCity: x.clmLocCity,
                        clmLocSplc: x.clmLocSplc,
                        clmLocState: x.clmLocState,
                        clmRoad: x.clmRoad,
                        clmSightCode: x.clmSightCode,
                        custNo: null,
                        divert: true,
                        fleetId: x.fleetId,
                        oneWayRound: x.oneWayRound,
                        orderNo: null,
                        ormId: x.ormId,
                        prevBolNo: x.bolNo,
                        prevBusinessGroup: x.businessGroup,
                        prevCustNo: x.custNo,
                        prevDestCity: x.destCity,
                        prevDestState: x.destState,
                        prevFleetId: x.fleetId,
                        prevOrderNo: x.tripNo,
                        prevReturnCity: x.returnCity,
                        prevReturnState: x.returnState,
                        prevRouteCode: x.routeCode,
                        // returnCity: null, // Don't need
                        returnSplc: null,
                        // returnState: null, // Don't need
                        routeCode: null,
                        routeDescription: null,
                        shipDateTime: x.shipDateTime,
                        shipmentType: x.shipmentType,
                        tcmEquipmentOrmId: x.tcmEquipmentOrmId,
                        tripErrorCount: x.tripErrorCount,
                        errorShipDateTime: x.errorShipDateTime,
                    };

                    // Group by Road
                    const v = x.clmRoad || '';
                    const el = rv.find((r) => r && r.road === v);
                    if (el) {
                        el.diversions.push(d);
                    } else {
                        rv.push({
                            road: v,
                            returnSplc: null,
                            routeCode: null,
                            diversions: [d],
                        });
                    }
                    return rv;
                }, []) || [];

            return groupedData;
        }

        return [];
    }
}

@Injectable({
    providedIn: 'root',
})
export class RtDiversionAdapter extends TwoWayAdapter<RtDiversionFormModel> {
    constructor() {
        super();
    }
    public metadata: IModelMetadata[] = relationship;
}
