import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { DxDateBoxModule } from 'devextreme-angular/ui/date-box';
import { DxLookupModule } from 'devextreme-angular/ui/lookup';
import { BourqueCoreModule } from '@bds/core';
import { BdsSmartComponentsModule } from '@bds/smart-components';
import { BdsCustomerSmartComponentsModule } from '@bds/customer-smart-components';
import { RtRouteSmartComponentsModule } from '@bds/route';
import { RtDiversionDetailsComponent } from './rt-diversion-details/rt-diversion-details.component';
import { RtDiversionComponent } from './rt-diversion/rt-diversion.component';
import { RtDiversionListComponent } from './rt-diversion-list/rt-diversion-list.component';
import { RtNewDiversionComponent } from './rt-new-diversion/rt-new-diversion.component';
import { RtDiversionListItemComponent } from './rt-diversion-list-item/rt-diversion-list-item.component';
import { RtMultiDiversionComponent } from './rt-multi-diversion/rt-multi-diversion.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatToolbar } from '@angular/material/toolbar';

@NgModule({
    declarations: [
        RtDiversionDetailsComponent,
        RtDiversionComponent,
        RtDiversionListComponent,
        RtNewDiversionComponent,
        RtDiversionListItemComponent,
        RtMultiDiversionComponent,
    ],
    imports: [
        BdsCustomerSmartComponentsModule,
        BdsSmartComponentsModule,
        BourqueCoreModule,
        CommonModule,
        DxDataGridModule,
        DxDateBoxModule,
        DxLookupModule,
        FontAwesomeModule,
        MatButtonModule,
        MatCardModule,
        MatDialogModule,
        MatDividerModule,
        MatFormFieldModule,
        MatInputModule,
        MatCheckboxModule,
        MatSelectModule,
        MatSnackBarModule,
        MatToolbar,
        ReactiveFormsModule,
        RtRouteSmartComponentsModule,
    ],
    exports: [RtDiversionComponent, RtMultiDiversionComponent, RtNewDiversionComponent],
})
export class RtDiversionModule {}
