import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Optional,
    Output,
    Self,
    ViewChild,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { RtErpcOrigin } from '@bds/railtrac-models';
import { DxSelectBoxComponent } from 'devextreme-angular/ui/select-box';
import DataSource from 'devextreme/data/data_source';
import { BdsMatFormField } from '../../abstract-components/bds-abstract-control-value.accessor';
import { dxMatStyle } from '../../helpers/dx-mat-style';
import { BdsErpcOriginService, BdsSplcErpcService } from '@bds/data-access';
import { MatDialog } from '@angular/material/dialog';
import { BdsErpcOriginSelectDialogComponent } from './bds-erpc-origin-select-dialog/bds-erpc-origin-select-dialog.component';
import { BdsErpcOriginGridDialogModel } from '../models/bds-erpc-origin-grid-dialog.model';
import ODataStore from 'devextreme/data/odata/store';
import { BdsErpcOriginGridDialogComponent } from './bds-erpc-origin-grid-dialog/bds-erpc-origin-grid-dialog.component';
import { take } from 'rxjs';
import { BdsDialogInfoComponent, BdsDialogInfoModel } from '@bds/components';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
    selector: 'bds-erpc-origin-select',
    template: `
        <dx-select-box
            [stylingMode]="style"
            [class.bds-mat-dense-form-field]="dense"
            [class.dx-mat-form-field-floatLabel-always]="matFloatLabel === 'always'"
            class="bds-dx-custom-material flex-fill"
            style="opacity: 1; "
            [style.margin-bottom.px]="errors ? -1 : 0"
            [(ngModel)]="value"
            [dataSource]="dataSource"
            [valueExpr]="valueExpr"
            [displayExpr]="splcDisplayExpr"
            [searchEnabled]="true"
            searchTimeout="200"
            [dropDownOptions]="popupDim"
            itemTemplate="item"
            fieldTemplate="field"
            [disabled]="disabled"
            (onSelectionChanged)="selected.emit($event?.selectedItem)"
        >
            <dxi-button
                *ngIf="enableAdd"
                name="addErpcOrigin"
                [options]="originAddInlineButtonOptions"
            ></dxi-button>
            <dxi-button
                *ngIf="enableEdit"
                name="editErpcOrigin"
                [options]="originEditInlineButtonOptions"
            ></dxi-button>
            <dxi-button
                *ngIf="enableSearchPopup"
                name="searchOrigin"
                [options]="searchButtonOptions"
            ></dxi-button>
            <dxi-button name="dropDown"></dxi-button>
            <div *dxTemplate="let data of 'field'">
                <dx-text-box
                    style="display:inline-block"
                    width="100%"
                    [value]="getDisplayValue(data)"
                    [label]="!required ? placeholder : placeholder + ' *'"
                ></dx-text-box>
            </div>
            <div *dxTemplate="let data of 'item'">
                <div class="custom-icon">
                    {{ searchDisplay(data) }}
                </div>
            </div>
        </dx-select-box>
        <ng-content></ng-content>
        <div></div>
    `,
    styleUrls: [],
    providers: [{ provide: MatFormFieldControl, useExisting: BdsErpcOriginSelectComponent }],
})
export class BdsErpcOriginSelectComponent extends BdsMatFormField<string> implements OnInit {
    dataSource: DataSource;
    @Input() dense = true;
    @Input() displayValue = this.defaultDisplay;
    @ViewChild(DxSelectBoxComponent) dxSelectBox;
    @Input() enableSearchPopup = false;
    @Input() enableEdit = false;
    @Input() enableAdd = false;
    @Input() newWindowForAdd = false;
    @Input() newWindowForEdit = false;
    id: string;
    @Input() matFloatLabel = 'always';
    @Input() matStyle: 'fill' | 'outline' | 'standard' | 'legacy' = 'fill';
    @Input() pageSize = 10;
    @Input() initValue;
    @Input() title: string;
    popupDim = { minWidth: '250px' };
    searchButtonOptions = {
        type: 'default',
        icon: 'search',
        stylingMode: 'text',
        onClick: () => {
            this.openSearchDialog();
        },
    };
    @Input() searchDisplay = this.defaultSearchDisplay;
    @Output() selected = new EventEmitter<RtErpcOrigin>();
    @Input() splcDisplayExpr: string[] = ['erpcOrigin', 'erpcState', 'originCode'];
    @Input() valueExpr: 'splc' | undefined = 'splc';

    erpcOrigin: RtErpcOrigin;

    constructor(
        private erpcOriginService: BdsErpcOriginService,
        @Optional() @Self() public ngControl: NgControl,
        private dialog: MatDialog,
        private snackbar: MatSnackBar,
    ) {
        super(ngControl);
    }

    private _filter: [];

    get filter() {
        return this._filter;
    }

    get errors() {
        return this.ngControl?.errors?.length > 0;
    }

    get required() {
        return this.ngControl?.errors?.required;
    }

    @Input() set filter(value: []) {
        this._filter = value;
    }

    @Input() set label(plh: string) {
        super.placeholder = plh;
    }

    get style(): string {
        return dxMatStyle(this.matStyle);
    }

    defaultDisplay(data: RtErpcOrigin) {
        return data ? `${data.originCode}` : '';
    }

    defaultSearchDisplay(data: RtErpcOrigin) {
        return data ? `${data?.erpcOrigin}, ${data?.erpcState} (${data?.originCode ?? ''})` : '';
    }

    getDisplayValue(data: RtErpcOrigin) {
        return this.displayValue(data);
    }

    ngOnInit(): void {
        this.dataSource = new DataSource({
            store: this.erpcOriginService.getODataStore(),
            paginate: true,
            pageSize: 10,
            searchExpr: this.splcDisplayExpr,
            select: ['erpcOrigin', 'erpcState', 'splc', 'originCode', 'masterOriginYorn'],
            sort: ['erpcOrigin', 'erpcState'],
            key: 'originCode',
        });
    }

    private openSearchDialog() {
        const dialogRef = this.dialog.open(BdsErpcOriginSelectDialogComponent, {
            data: { filter: this.filter, title: this.title },
        });

        dialogRef.afterClosed().subscribe((data) => {
            if (data[0]) {
                let value = data[0];
                this.value = value[this.valueExpr];
                this.selected.emit(value);
            }
        });
    }

    originAddInlineButtonOptions = {
        type: 'default',
        icon: 'add',
        stylingMode: 'text',
        onClick: (): void => {
            this.onAddOrigin();
        },
    };

    originEditInlineButtonOptions = {
        type: 'default',
        icon: 'edit',
        stylingMode: 'text',
        onClick: (): void => {
            this.onEditOrigin(this.dxSelectBox.selectedItem);
        },
    };

    onAddOrigin(): void {
        if (this.newWindowForAdd) {
            window.open('origins', '_blank').focus();
            return;
        }

        const dialogData: BdsErpcOriginGridDialogModel = {
            isNew: true,
            origin: new RtErpcOrigin(),
            originId: undefined,
            splcStore: this.erpcOriginService.getODataStore(),
        };
        const dialogRef = this.dialog.open(BdsErpcOriginGridDialogComponent, {
            width: '750px',
            data: dialogData,
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result !== undefined) {
                console.log(result);

                this.openSnackBar(result?.message, '', true);
                this.selected.emit(result?.origin);
            }
        });
    }

    onEditOrigin(origin: RtErpcOrigin): void {
        if (this.newWindowForEdit) {
            window.open('origins', '_blank').focus();
            return;
        }

        if (origin) {
            this.erpcOriginService
                .getBySplc(origin.splc)
                .pipe(take(1))
                .subscribe((orig) => {
                    const dialogData: BdsErpcOriginGridDialogModel = {
                        isNew: false,
                        origin: orig,
                        originId: orig.ormId,
                        splcStore: this.erpcOriginService.getODataStore(),
                    };
                    const dialogRef = this.dialog.open(BdsErpcOriginGridDialogComponent, {
                        width: '750px',
                        data: dialogData,
                    });
                    dialogRef.afterClosed().subscribe((result) => {
                        if (result !== undefined) {
                            console.log(result);

                            this.openSnackBar(result?.message, '', true);
                            this.selected.emit(this.dxSelectBox.selectedItem);
                        }
                    });
                });
        } else {
            const info: BdsDialogInfoModel = {
                title: 'Edit an Origin',
                content: 'Please select an Origin to edit.',
            };
            this.dialog.open(BdsDialogInfoComponent, {
                data: info,
            });
        }
    }

    openSnackBar(message: string, action: string, success: boolean): void {
        let style = 'error-snack';
        if (success) {
            style = 'success-snack';
        }

        this.snackbar.open(message, action, {
            duration: 3000,
            panelClass: [style],
            horizontalPosition: 'right',
        });
    }
}
