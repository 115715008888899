import { NgModule } from '@angular/core';
// NOTE: If this module is moved elsewhere, please move rt-route-smart-components as a sibling module or library
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { BdsDialogConfirmModule, BdsDxDataGridModule } from '@bds/components';
import { BourqueCoreModule } from '@bds/core';
import { BdsFastracModule } from '@bds/fastrac';
import { BdsDataAccessModule } from '@bds/data-access';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { DxDateBoxModule } from 'devextreme-angular';

import { BdsRouteParticipatingRailroadDialogComponent } from './components/bds-route-participating-railroad-dialog/bds-route-participating-railroad-dialog.component';
import { BdsRouteParticipatingRailroadDialogOneComponent } from './components/bds-route-participating-railroad-dialog-one/bds-route-participating-railroad-dialog-one.component';
import { BdsRouteParticipatingRailroadDialogTwoComponent } from './components/bds-route-participating-railroad-dialog-two/bds-route-participating-railroad-dialog-two.component';
import { BdsRouteParticipatingRailroadComponent } from './components/bds-route-participating-railroad/bds-route-participating-railroad.component';

@NgModule({
    declarations: [
        BdsRouteParticipatingRailroadDialogComponent,
        BdsRouteParticipatingRailroadDialogOneComponent,
        BdsRouteParticipatingRailroadDialogTwoComponent,
        BdsRouteParticipatingRailroadComponent,
    ],
    imports: [
        DxDataGridModule,
        BdsFastracModule,
        BdsDialogConfirmModule,
        BdsDxDataGridModule,
        BourqueCoreModule,
        BdsDataAccessModule,
        MatDialogModule,
        MatFormFieldModule,
        FormsModule,
        ReactiveFormsModule,
        MatDividerModule,
        MatButtonModule,
        MatCheckboxModule,
        MatInputModule,
        MatSlideToggleModule,
        MatTableModule,
        MatToolbarModule,
        FontAwesomeModule,
        DxDateBoxModule,
        CommonModule,
        RouterModule,
    ],
    exports: [
        BdsRouteParticipatingRailroadDialogComponent,
        BdsRouteParticipatingRailroadDialogOneComponent,
        BdsRouteParticipatingRailroadDialogTwoComponent,
        BdsRouteParticipatingRailroadComponent,
    ],
})
export class ParticipatingRailroadModule {}
